import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import { Link, useHistory } from "react-router-dom";
import { Col, Row, Tab, Form, FormControl } from "react-bootstrap";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { generateHashKey2 } from "../../utils/utilFuctions";
import { Select } from "semantic-ui-react";
import { billpunchbasicauth } from "../../utils/Credentials";

const EmployeeFormHR = () => {
  const history = useHistory();
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState({ bankName: "", ifsc: "" });
  const { password , username } = billpunchbasicauth;
  const { user } = useContext(GlobalContext);
  const [departmentData, setDepartmentData] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    firmid: "",
    firstname: "",
    middlename: "",
    lastname: "",
    mobile: "",
    designation: "",
    department: "",
    emp_type: "",
    address: "",
    email: "",
    dob: new Date(),
    epf_no: "",
    aadharno: "",
    panno: "",
    joiningdate: new Date(),
    bankName: "",
    accountNumber: "",
    // ifscCode: "",
    workemail:"",
    pan:"",
    qualification:"",
  });
  const [errors, setErrors] = useState({
    firmid: "",
    firstname: "",
    middlename: "",
    lastname: "",
    mobile: "",
    designation: "",
    department: "",
    emp_type: "",
    address: "",
    email: "",
    dob: "",
    epf_no: "",
    aadharno: "",
    panno: "",
    joiningdate: "",
    bankName: "",
    accountNumber: "",
    // ifscCode: "",
    workemail:"",
    pan:"",
    qualification:"",
  });
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(formData);
    let errors = {};
  
    const spaceRegex = /^\s+/; 
  
    if (spaceRegex.test(formData.firstname)) {
      errors.firstname = "*First name should not start with a space";
    } else if (formData.firstname.trim() === "") {
      errors.firstname = "*Please enter first Name";
    }
  
    if (spaceRegex.test(formData.lastname)) {
      errors.lastname = "*Last name should not start with a space";
    } else if (formData.lastname.trim() === "") {
      errors.lastname = "*Please enter last name";
    }
  
    if (spaceRegex.test(formData.mobile)) {
      errors.mobile = "*Mobile should not start with a space";
    } else if (formData.mobile.trim() === "") {
      errors.mobile = "*Please enter Mobile";
    }
  
    if (spaceRegex.test(formData.email)) {
      errors.email = "*Email should not start with a space";
    } else if (formData.email.trim() === "") {
      errors.email = "*Please enter Email";
    }
  
    if (spaceRegex.test(formData.address)) {
      errors.address = "*Address should not start with a space";
    } else if (formData.address.trim() === "") {
      errors.address = "*Please enter Address";
    }
  
    if (spaceRegex.test(formData.designation)) {
      errors.designation = "*Designation should not start with a space";
    } else if (formData.designation.trim() === "") {
      errors.designation = "*Please enter Designation";
    }
  
    if (!formData.dob || spaceRegex.test(formData.dob.toString())) {
      errors.dob = "*Please select a valid Date of Birth";
    }
  
    if (spaceRegex.test(formData.department)) {
      errors.department = "*Department should not start with a space";
    } else if (formData.department.trim() === "") {
      errors.department = "*Please select Department";
    }
  
    if (spaceRegex.test(formData.emp_type)) {
      errors.emp_type = "*Employee type should not start with a space";
    } else if (formData.emp_type.trim() === "") {
      errors.emp_type = "*Please select employee type";
    }
  
    if (!formData.joiningdate || spaceRegex.test(formData.joiningdate.toString())) {
      errors.joiningdate = "*Please select a valid Joining Date";
    }
  
    if (spaceRegex.test(formData.bankName)) {
      errors.bankName = "*Bank Name should not start with a space";
    } else if (formData.bankName.trim() === "") {
      errors.bankName = "*Please enter Bank Name";
    }
  
    if (spaceRegex.test(formData.accountNumber)) {
      errors.accountNumber = "*Bank Account Number should not start with a space";
    } else if (formData.accountNumber.trim() === "") {
      errors.accountNumber = "*Please enter Bank Account Number";
    }
  

    if (spaceRegex.test(formData.workemail)) {
      errors.workemail = "*Work email should not start with a space";
    } else if (formData.workemail.trim() === "") {
      errors.workemail = "*Please enter Work email";
    }
  
    if (spaceRegex.test(formData.pan)) {
      errors.pan = "*Pan should not start with a space";
    } else if (formData.pan.trim() === "") {
      errors.pan = "*Please enter Pan";
    }
  
    if (spaceRegex.test(formData.qualification)) {
      errors.qualification = "*Qualification should not start with a space";
    } else if (formData.qualification.trim() === "") {
      errors.qualification = "*Please enter Qualification";
    }

    if (!formData.joiningdate) {
      errors.joiningdate = "*Please select a valid Joining Date";
    }
    if (!formData.dob) {
      errors.dob = "*Please select a valid Date of Birth";
    }
    setErrors(errors);
    console.log("Validation Errors:", errors);
    console.log("handle change button 1 ");
    if (Object.keys(errors).length === 0) {
      handleSubmit();
      console.log("handle change button n ");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "bankName") {
      const foundBank = banks.find((bank) => bank.bankName === value);
      setSelectedBank(foundBank || { bankName: "", ifsc: "" });
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({  firmid:  user.firm_id,});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const formatDateString = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleSubmit = async (e) => {
    try {
      console.log("Inside handleSubmit");

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      console.log(handleSubmit, "kill");
      console.log("shivani ", formData);
      var raw = JSON.stringify({
        firm_id: user.firm_id,
        firstname: formData.firstname,
        middlename: formData.middlename,
        lastname: formData.lastname,
        mobile: formData.mobile,
        designation: formData.designation,
        department: formData.department,
        emp_type: formData.emp_type,
        address: formData.address,
        work_email:formData.workemail,
        email: formData.email,
        dob: formatDateString(formData.dob),
        joining_date: formatDateString(formData.joiningdate),
        bankname: formData.accountNumber, 
        bankifsc:formData.ifscCode,
        bankaccno: formData.bankName, 
        qualification:formData.qualification,
        pan: formData.pan,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addEmployee`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        history.push("/retailer/EmployeeListAdmin");
      } else {
     
        swal("Success", data.msg, "success");

      history.push("/retailer/EmployeeListAdmin");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const getBankList = async () => {
  //   try {
  //     setTransactionLoaderState(true);
  //     const raw = "";
  //     const hash_key = await generateHashKey2(user.ReferId);

  //     var myHeaders = new Headers();
  //     myHeaders.append("HASH_KEY", hash_key);
  //     myHeaders.append("Content-Type", "application/json");

  //     var requestOptions = {
  //       method: "POST",
  //       redirect: "follow",
  //       headers: myHeaders,
  //       body: raw,
  //     };

  //     const res = await fetch(
  //       "https://api.bumppypay.com/Fullkycdmt.aspx?dmt=banklistnew2&beneficiaryid=BR1052",
  //       requestOptions
  //     );

  //     if (!res.ok) {
  //       throw new Error(`HTTP error! Status: ${res.status}`);
  //     }

  //     const data = await res.json();

  //     if (data.status === "0") {
  //       setBanks(
  //         data.data.map((role) => {
  //           return { ...role, label: `${role.bankName}`, value: role.bankId };
  //         })
  //       );
  //     }

  //     setTransactionLoaderState(false);
  //   } catch (error) {
  //     console.error("Error in getBankList:", error);
  //     setTransactionLoaderState(false);
  //   }
  // };

  const getBanks = async () => {
    setTransactionLoaderState(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "banklistnew2");
    urlencoded.append("beneficiaryid", "111");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://api.bumppypay.com/Fullkycdmt.aspx?dmt=banklistnew2&beneficiaryid=BR1052",
        requestOptions
      );

      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data !== null && data.data !== null) {
        const options = data.data.bankList.map((bank) => {
          return { ...bank, label: bank.bankName, value: bank.ifsc };
        });
        setBanks(options);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDepartmentList();
    // getBanks();
  }, []);

  return (
    <>
      <div className="row m-1  d-flex " style={{ justifyContent: "center" }}>
        <div
          className="col-md-8 col-sm-12 m-0 card"
          style={{ borderRadius: ".4rem", justifyContent: "center" }}
        >
          {" "}
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Link to={"/retailer/EmployeeListAdmin"} className="mr-md-4">
              {" "}
              <button
                type="button"
                className="mt-md-2 mr-md-5  btn-sm btn-primary"
                style={{
                  color: "#fff",
                  border: "none",
                }}
              >
                Back
              </button>
            </Link>
          </div>
          <div className="tab-custom-pills-horizontal ">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="p-0 m-0">
                <Col xs={12}>
                  <form onSubmit={validate} className="mx-md-5 m-md-2 ">
                    <h6 className="mx-md-2 mx-sm-2">Employee Information</h6>
                    <div className="row">
                      {" "}
                      <Form.Group className="col-6 m-0">
                        {/* <Form.Label> First Name</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          id="firstname"
                          name="firstname"
                          value={formData.firstname}
                          onChange={handleChange}
                        />
                        {errors.firstname && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.firstname}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-6 m-0">
                        {/* <Form.Label> Middle Name</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="Middle Name"
                          id="middlename"
                          name="middlename"
                          value={formData.middlename}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      {" "}
                      <Form.Group className="col-6 m-0" controlId="lname">
                        {/* <Form.Label>Last Name</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          id="lastname"
                          name="lastname"
                          value={formData.lastname}
                          onChange={handleChange}
                        />
                        {errors.lastname && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.lastname}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-6 m-0" controlId="phone">
                        {/* <label htmlFor="name">Mobile No</label> */}
                        <Form.Control
                          maxLength={10}
                          type="text"
                          placeholder="Mobile No"
                          id="mobile"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                        {errors.mobile && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.mobile}</div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="row">
                      <Form.Group className=" col-6 m-0" controlId="email">
                        {/* <label htmlFor="name">Email ID</label> */}
                        <Form.Control
                          type="email"
                          placeholder="Email ID"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.email}</div>
                        )}
                      </Form.Group>
                      <Form.Group className=" col-6 m-0" controlId="Workemail">
                        {/* <label htmlFor="name">Email ID</label> */}
                        <Form.Control
                          type="email"
                          placeholder="Work Email ID"
                          id="workemail"
                          name="workemail"
                          value={formData.workemail}
                          onChange={handleChange}
                        />
                        {errors.workemail && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.workemail}</div>
                        )}
                      </Form.Group>
                     
                    </div>
                    <div className="row">
                    <Form.Group className="col-6 m-0" controlId="Designation">
                        {/* <Form.Label>Designation</Form.Label> */}
                        <Form.Control
                          type="text"
                          id="designation"
                          placeholder="Designation"
                          name="designation"
                          value={formData.designation}
                          onChange={handleChange}
                        />
                        {errors.designation && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>
                            {errors.designation}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-6 m-0" controlId="Designation">
                        {/* <Form.Label>Designation</Form.Label> */}
                        <Form.Control
                          type="text"
                          id="qualification"
                          placeholder="Qualification"
                          name="qualification"
                          value={formData.qualification}
                          onChange={handleChange}
                        />
                        {errors.qualification && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>
                            {errors.qualification}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-6 m-0" controlId="Department">
                        <Form.Control
                          as="select"
                          name="department"
                          value={formData.department}
                          onChange={handleChange}
                        >
                          <option value="">Department</option>
                          {departmentData.map((dept) => (
                            <option key={dept.id} value={dept.cat1}>
                              {dept.cat1}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.department && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.department}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-6 m-0" controlId="Emptype">
                        <Form.Control
                          as="select"
                          name="emp_type"
                          value={formData.emp_type} // Set the selected value
                          onChange={handleChange}
                        >
                          <option value="">Employee Type</option>
                          <option value="Intern">Intern</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Contract Based">Contract Based</option>
                        </Form.Control>
                        {errors.emp_type && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.emp_type}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-6 m-0" controlId="Address">
                        {/* <label htmlFor="name">Enter Your Address</label> */}
                        <Form.Control
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Enter Your address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                        {errors.address && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.address}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-6 m-0" controlId="DOB">
                        <DatePicker
                          selected={formData.dob}
                          onChange={(dob) => setFormData({ ...formData, dob })}
                          showYearDropdown
                          dateFormatCalendar="MMMM yyyy"
                          yearDropdownItemNumber={30}
                          scrollableYearDropdown
                          className="form-control"
                          placeholderText="Date of Birth"
                        />
                        {errors.dob && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.dob}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="row">
                    <Form.Group className="col-6 m-0" controlId="bankName">
                        {/* <label htmlFor="name">Enter Your Bank Name</label> */}
                        <Form.Control
                          type="text"
                          id="bankName"
                          name="bankName"
                          placeholder="Enter Your Bank Name"
                          value={formData.bankName}
                          onChange={handleChange}
                        />
                        {errors.bankName && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.bankName}</div>
                        )}
                         </Form.Group>
                      {/* <Form.Group className="col-6" controlId="bankName">
                        <Form.Control
                          as="select"
                          name="bankName"
                          value={formData.bankName}
                          onChange={handleChange}
                        >
                          <option value="">Select Bank</option>
                          {banks.map((bank) => (
                            <option key={bank.bankCode} value={bank.bankName}>
                              {bank.bankName}
                            </option>
                          ))}
                        </Form.Control>

                        {errors.bankName && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.bankName}</div>
                        )}
                      </Form.Group> */}

                      {/* <Form.Group
                        className="col-6 form-group"
                        controlId="ifscCode"
                      >
                        <Form.Control
                          type="text"
                          placeholder="IFSC Code"
                          id="ifscCode"
                          name="ifscCode"
                          value={selectedBank.ifsc}
                          onChange={handleChange}
                        />
                        {errors.ifscCode && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.ifscCode}</div>
                        )}
                      </Form.Group> */}
                         <Form.Group className="col-6 m-0" controlId="ifscCode">
                        {/* <label htmlFor="name">Enter Your Bank ifscCode</label> */}
                        <Form.Control
                          type="text"
                          id="ifscCode"
                          name="ifscCode"
                          placeholder="Enter Your Bank ifscCode"
                          value={formData.ifscCode}
                          onChange={handleChange}
                        />
                        {errors.ifscCode && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.ifscCode}</div>
                        )}
                         </Form.Group>
                    </div>
                    <div className="row">
                    <Form.Group className="col-6 m-0" controlId="pan">
                      
                      <Form.Control
                            type="text"
                            placeholder="PAN"
                            id="pan"
                            name="pan"
                            value={formData.pan}
                            onChange={handleChange}
                          />
                          {errors.pan && (
                            <div className="text-danger" style={{fontSize:".8rem"}}>
                              {errors.pan}
                            </div>
                          )}
                        </Form.Group>
                 

                      <Form.Group className="col-6 m-0" controlId="accountNumber">
                        <Form.Control
                          type="text"
                          placeholder="Bank Account Number"
                          id="accountNumber"
                          name="accountNumber"
                          value={formData.accountNumber}
                          onChange={handleChange}
                        />
                        {errors.accountNumber && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>
                            {errors.accountNumber}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="row">
                    <Form.Group className="col-6 m-0" controlId="joiningdate">
                        <DatePicker
                          selected={formData.joiningdate}
                          onChange={(joiningdate) =>
                            setFormData({ ...formData, joiningdate })
                          }
                          showYearDropdown
                          dateFormatCalendar="MMMM yyyy"
                          yearDropdownItemNumber={30}
                          scrollableYearDropdown
                          className="form-control"
                          placeholderText="Joining Date"
                        />
                        {errors.joiningdate && (
                          <div className="text-danger" style={{fontSize:".8rem"}}>{errors.joiningdate}</div>
                        )}
                      </Form.Group>
                    
                    </div>
                    <div className="mx-md-2 mx-sm-2 ">
                      <Link>
                        <button
                          // type="submit"
                          className="btn btn-primary d-flex align-items-center "
                          style={{ height: "2rem", borderRadius: ".4rem" }}
                          onClick={validate}
                        >
                          Submit
                        </button>
                      </Link>
                    </div>
                  </form>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeFormHR;
