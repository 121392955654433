import React, { createContext, useReducer } from "react";
import { BASE_URL_API_TRANXT, BASE_URL_API_TRANXT_UAT_API } from "../utils/Constants";
import AppReducer from "./AppReducer";
import swal from "sweetalert";
import { generateHashKey2 } from "../utils/utilFuctions";
import {
  FaChartPie,
} from "react-icons/fa";
// get user if present in session storage
const user = JSON.parse(sessionStorage.getItem("user"));
const wallet = JSON.parse(sessionStorage.getItem("wallet"));
const accountDetails = JSON.parse(sessionStorage.getItem("accountDetails"));


// encryption soon
const initialState = {
  Profileinfo: null,
  myAccountInfo: accountDetails,
  token: null,
  user: user,
  wallet: wallet,
  transactionLoader: false,
  accessData: null,
  accessDataemp: null,
};
export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions for changing state

  function setTransactionLoaderState(loaderState) {
    dispatch({
      type: "SET_TRANSACTION_LOADER",
      payload: loaderState,
    });
  }

  function setToken(token) {
    dispatch({
      type: "SET_TOKEN",
      payload: token,
    });
  }
  function setProfileinfo(info) {
    console.log(info);
    dispatch({
      type: "SET_MY_ACCOUNT_INFO",
      payload: info,
    });
  }
  function setMyAccountInfo(info) {
    sessionStorage.setItem("accountDetails", JSON.stringify(info));
    dispatch({
      type: "SET_MY_ACCOUNT_INFO_DEVELOPER",
      payload: info,
    });
  }
  function logoutDeveloperLogin() {
    sessionStorage.removeItem("accountDetails");
    dispatch({
      type: "LOG_OUT_DEVELOPER",
      payload: null,
    });
  }
  function removeMyAccountInfo() {
    sessionStorage.removeItem("accountDetails");
    dispatch({
      type: "REMOVE_MY_ACCOUNT_INFO_DEVELOPER",
      payload: null,
    });
  }
  function setUserInfo(info) {
    sessionStorage.setItem("user", JSON.stringify(info));
    dispatch({
      type: "SET_USER_INFO",
      payload: info,
    });
  }
  function removeUserInfo() {
    sessionStorage.removeItem("user");
    dispatch({
      type: "REMOVE_USER_INFO",
      payload: null,
    });
  }

  function setWalletBalance(wallet) {
    sessionStorage.setItem("wallet", JSON.stringify(wallet));
    dispatch({
      type: "SET_WALLET",
      payload: wallet,
    });
    console.log(state);
  }
  function setAccessData(accessData) {
    dispatch({
      type: 'SET_ACCESS_DATA',
      payload: accessData
    });
  }

  function setAccessemployeData(accessDataemp) {
    console.log('Setting Access Data EMP:', accessDataemp);  
    dispatch({
      type: 'SET_ACCESS_DATA_EMP',
      payload: accessDataemp
    });
  }
  
  
  const getWalletBalance = async () => {
    try {
      const raw = "";
      const hash_key = await generateHashKey2(state.user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${state.user.ReferId}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      setWalletBalance(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  function refreshWallet() {
    getWalletBalance();
  }

  const checkWalletBeforeBBPSBillPay = async (amount, callback, callback2) => {
    //WalletBalance
    //wallet
    try {
      const raw = "";
      const hash_key = await generateHashKey2(state.user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${state.user.ReferId}`,
        requestOptions
      );
      const data = await res.json();
      setWalletBalance(data);
      if (data.WalletBalance < amount) {
        swal("Insufficient Balance", `Transaction Amount ${amount}`, "error");
        callback2();
      } else {
        callback();
      }
    } catch (error) {
      swal("Insufficient Balance", `Transaction Amount ${amount}`, "error");
      console.log("error", error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        accessData: state.accessData,
        setAccessData,
        accessDataemp: state.accessDataemp,
        setAccessemployeData,
        token: state.token,
        myAccountInfo: state.myAccountInfo,
        user: state.user,
        Profileinfo: state.Profileinfo,
        setToken,
        setUserInfo,
        removeUserInfo,
        setProfileinfo,
        setMyAccountInfo,
        setWalletBalance,
        wallet: state.wallet,
        transactionLoader: state.transactionLoader,
        refreshWallet,
        checkWalletBeforeBBPSBillPay,
        setTransactionLoaderState,
        removeMyAccountInfo,
        logoutDeveloperLogin
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};



