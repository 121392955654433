import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import swal from "sweetalert";
import Select from "react-select";

import { Container, Accordion, Card, Button } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Link } from "react-router-dom";
import { billpunchbasicauth } from "../../utils/Credentials";

const PayrollAdminAccess = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password , username } = billpunchbasicauth;
 
  const [Employee, setEmployee] = useState([]);
  const [accessData, setAccessData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeeSelected, setEmployeeSelected] = useState(false);

  const Getemployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setEmployee(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching access data:", error);
      setTransactionLoaderState(false);
    }
  };

  const getAccessData = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers:myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          empid: selectedEmployee.value,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAccessManagementAdmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setAccessData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching access data:", error);
      setTransactionLoaderState(false);
    }
  };

  const handleEmployeeSelect = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    setEmployeeSelected(true);
  };

  const handleSwitchChange = async (roleId, checked) => {
    const module = accessData.find((m) =>
      m.data.some((r) => r.pk_role_id === roleId)
    );
    const module_id = module.module_id;

    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers:myHeaders,
        body: JSON.stringify({
          empid: selectedEmployee.value,
          moduleid: module_id,
          roleid: roleId,
          access: checked ? "Y" : "N",
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/UpdateAccessAdmin`,
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");
        getAccessData();
      } else {
        swal("Error", data.msg, "warning");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error updating access data:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getemployee();
  }, []);

  useEffect(() => {
    if (employeeSelected) {
      getAccessData();
    }
  }, [selectedEmployee]);
  const resetSelection = () => {
    setEmployeeSelected(false);
    setSelectedEmployee(null);
  };
  return (
    <>
      <div className="m-2">
        {!employeeSelected && (
          <>
            {" "}
            <div className="row ">
              <div className="col d-flex align-items-center">
                <h5 className="mx-md- mx-sm-2">
                  Select Employee To Provide Access
                </h5>
              </div>
            </div>
            <div className="row px-3">
              <div className="col">
                {" "}
                <Select
                  value={selectedEmployee}
                  onChange={handleEmployeeSelect}
                  options={Employee.map((employee) => ({
                    value: employee.empid,
                    label: `${employee.firstname} ${employee.lastname}`,
                  }))}
                  placeholder="Select Employee"
                />
              </div>
            </div>
          </>
        )}
        {employeeSelected && (
          <>
            <div className="row d-flex align-items-center mx-md-2 mx-sm-1 mt-4">
              <h5 className="col ">Access Management</h5>
              <div className="col d-flex justify-content-end">
                {" "}
                <Button
                  onClick={resetSelection}
                  variant="secondary"
                  className=""
                >
                  Back to Employee Selection
                </Button>
              </div>
            </div>
            <Container className="">
              <Accordion>
                {accessData.map((module, index) => (
                  <Card className="m-0 p-2" key={index}>
                    <Card.Header
                      as={Accordion.Toggle}
                      eventKey={index.toString()}
                      className="m-0 p-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: ".2rem",
                        border: "1px solid rgb(170, 215, 189)",
                        background: "rgb(236, 246, 240)",
                      }}
                    >
                      <div>{module.module_name}</div>
                      <div>
                        {" "}
                        <RiArrowDropDownLine
                          style={{ fontSize: "1.5rem" }}
                        />{" "}
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body
                        className="mt-2"
                        style={{
                          background: "#FEFAF6",
                          border: "1px solid rgb(170, 215, 189)",
                        }}
                      >
                        <div className="mt-2">
                          {module.data.map((role) => (
                            <div
                              key={role.pk_role_id}
                              className="d-flex align-items-center justify-content-between mb-2"
                            >
                              <div
                                style={{ fontWeight: "600", fontSize: ".8rem" }}
                              >
                                {" "}
                                {role.role_name}{" "}
                              </div>
                              <BootstrapSwitchButton
                                checked={role.access === "Y"}
                                onstyle="success"
                                offstyle="secondary"
                                size="sm"
                                onChange={(checked) =>
                                  handleSwitchChange(role.pk_role_id, checked)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default PayrollAdminAccess;
