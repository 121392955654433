import React from "react";
import { Button } from "react-bootstrap";
import Footer from "./footer";
import Navbar from "./Navbar";

const Article = () => {
  const selectedBlog = JSON.parse(sessionStorage.getItem("selectedBlog"));

  // Helper function to split HTML content into different elements
  const parseHtmlContent = (htmlContent) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = htmlContent;
    return Array.from(wrapper.children);
  };

  const contentBlocks = parseHtmlContent(selectedBlog.post_content);

  return (
    <>
      <Navbar />
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col">
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              variant="info"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
          </div>

         <div className="col-9 mt-2">
            <h1>{selectedBlog.post_title}</h1>
            <p>{selectedBlog.post_excerpt}</p>
            <p className="text-muted">
              Published on: {new Date(selectedBlog.created_at).toLocaleDateString()}
            </p>
           {contentBlocks.map((block, index) => {
              if (block.tagName === "H1") {
                return <h1 key={index} dangerouslySetInnerHTML={{ __html: block.outerHTML }} />;
              } else if (block.tagName === "P") {
                return <p key={index} dangerouslySetInnerHTML={{ __html: block.outerHTML }} />;
              } else if (block.tagName === "IMG") {
                return <img key={index} src={block.src} alt={block.alt} style={{ maxWidth: "100%" }} />;
              } else if (block.tagName === "BLOCKQUOTE") {
                return <blockquote key={index} dangerouslySetInnerHTML={{ __html: block.outerHTML }} />;
              } else {
                return <div key={index} dangerouslySetInnerHTML={{ __html: block.outerHTML }} />;
              }
            })}

          
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
      <Footer />
    </>
  );
};

export default Article;
