import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card, Button, Badge } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Viewsalaryslipemployee } from "./Modal";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { RiArrowDropDownLine } from "react-icons/ri";

const Salaryslipemployee = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [viewModalShow, setViewModalShow] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("January");

  const handleViewButtonClick = () => {
    setViewModalShow(true);
  };

  const handleCloseModal = () => {
    setViewModalShow(false);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthClick = async (month) => {
    setSelectedMonth(month);
    await getEmployeeSalarySlip(month);
  };

  const getEmployeeSalarySlip = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          month: month,
          firmid: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeSalarySlip`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const employeeData = data.data[0];
        setEmployee({
          ...employeeData,
          current_month: month,
        });
      } else {
        setEmployee(null);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getEmployeeSalarySlip(selectedMonth);
  }, [selectedMonth]);

  return (
    <>
      <div className="row px-3 pt-3">
        <div className="col-md-8 col-4">
          <h4>Salary Slip</h4>
        </div>
      </div>
      <Accordion className="mx-3" defaultActiveKey="">
        {months.map((month, index) => (
          <Card className="p-0" key={index}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={index.toString()}
              onClick={() => handleMonthClick(month)}
              className="m-0 p-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: ".2rem",
                border: "1px solid rgb(170, 215, 189)",
                background: "rgb(236, 246, 240)",
              }}
            >
              <div> {month}</div>
              <div>
                {" "}
                <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />{" "}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body
                // className="mt-2"
                style={{
                  background: "#FEFAF6",
                  border: "1px solid rgb(170, 215, 189)",
                }}
              >
                {employee ? (
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        background: "#fff",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Emp Code
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Month
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Gross Salary
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Net Pay
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #f2f4f2",
                            color: "#000",
                            padding: "8px",
                            textAlign: "left",
                            background: "#fff",
                          }}
                        >
                          Salary Slip
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ border: "none", cursor: "pointer" }}>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {employee.emp_code || "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {employee.name || "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {employee.current_month || "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {employee.totalSalarywithpf || "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {twoDecimalPlaces(employee.totalSalary || "N/A")}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor:
                                employee.status === "Y"
                                  ? "rgba(43, 193, 85, 0.1)"
                                  : "rgba(255, 0, 0, 0.1)",
                              color:
                                employee.status === "Y" ? "#2bc155" : "#ff0000",
                              border:
                                employee.status === "Y"
                                  ? "1px solid #2bc155"
                                  : "1px solid #ff0000",
                              borderRadius: ".3rem",
                              fontSize: "1rem",
                              display: "inline-block",
                              textAlign: "center",
                            }}
                          >
                            {employee.status === "Y"
                              ? "Processed"
                              : "Unprocessed"}
                          </Badge>
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          <Button
                            className="btn-sm btn mt-2"
                            onClick={handleViewButtonClick}
                          >
                            View salary slip
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div>No data found for {month} month</div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
      <Viewsalaryslipemployee
        show={viewModalShow}
        handleClose={handleCloseModal}
        employee={employee}
      />
    </>
  );
};

export default Salaryslipemployee;
