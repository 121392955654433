import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Link } from "react-router-dom";
import { AddWorkspaceadmin, Adminassignemployeeworkspace } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Button } from "react-bootstrap";
import Select from "react-select";

const Createworkspaceadmin = () => {
  const chartContainer = useRef(null);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [workspacedata, setworkspacedata] = useState([]);
  const [filteredWorkspacedata, setFilteredWorkspacedata] = useState([]);
  const [showaddworkspacetModal, setshowaddworkspacetModal] = useState(false);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const getWorkspacedata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: 0,
          firmid: user.firm_id,
          ownerid: user.userid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetYourWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setworkspacedata(data.data);
        setFilteredWorkspacedata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const getProjects = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const options = data.data.map((project) => ({
          value: project.pk_project_id,
          label: project.project_name,
        }));

        options.unshift({ value: "", label: "Clear Filter" });

        setProjectOptions(options);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getWorkspacedata();
    getProjects();
  }, []);

  const handleAddEmployeeModalOpen = (workspaceId, projectId) => {
    setSelectedWorkspaceId(workspaceId);
    setSelectedProject(projectId);
    setShowAddEmployeeModal(true);
  };

  const handleAddEmployeeModalClose = () => {
    setShowAddEmployeeModal(false);
  };

  const openaddprojectModal = () => {
    setshowaddworkspacetModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddworkspacetModal(false);
  };
  const handleProjectChange = (selectedOption) => {
    setSelectedProjectId(selectedOption.value);
    if (selectedOption.value === "") {
      setFilteredWorkspacedata(workspacedata);
    } else {
      const filteredData = workspacedata.filter(
        (item) => item.pk_project_id === selectedOption.value
      );
      setFilteredWorkspacedata(filteredData);
    }
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filteredData = workspacedata.filter((workspace) => {
      return (
        workspace.workspace_name.toLowerCase().includes(inputValue) ||
        workspace.workspce_desc.toLowerCase().includes(inputValue) ||
        workspace.lettersdata.some((employee) =>
          employee.emp_name.toLowerCase().includes(inputValue)
        )
      );
    });

    setFilteredWorkspacedata(filteredData);
  };

  return (
    <div className="p-md-3 p-2" style={{ background: "#fff" }}>
      <div className="row">
        <h4 className="col d-flex align-items-center">
          {user.organisation} Work space
        </h4>
        <div className="col d-flex justify-content-end">
          <div className="px-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search "
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="px-2">
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select Project"
              isClearable={true}
              value={selectedProject}
              onChange={handleProjectChange}
              options={projectOptions}
            />
          </div>

          <Button
            className="btn btn-sm justify-content-center align-items-center"
            style={{
              background: "#2ca01c",
              borderRadius: ".4em",
              border: "None",
              color: "#fff",
            }}
            onClick={openaddprojectModal}
          >
            Add Work space
          </Button>
        </div>
      </div>

      <div
        className="table-responsive"
        style={{
          borderRadius: ".4em",
          border: "1px solid lightgrey",
          marginTop: "1rem",
        }}
      >
        <table className="table table-bordered m-0 p-0">
          <thead style={{ top: 0, zIndex: 1, background: "#fff" }}>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#fff",
                  border: "none",
                }}
              >
                Project
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#fff",
                  border: "none",
                }}
              >
                Work Space
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#fff",
                  border: "none",
                }}
              >
                Description
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#fff",
                  border: "none",
                }}
              >
                Person
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#fff",
                  border: "none",
                }}
              >
                Team Size
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#fff",
                  border: "none",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredWorkspacedata.length === 0 ? (
              <tr>
                <td
                  colSpan="5"
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              filteredWorkspacedata.map((item, index) => (
                <tr style={{ borderBottom: "1px solid lightgrey" }} key={index}>
                  <td
                    className="text-center align-middle"
                    style={{
                      border: "none",
                      display: "flex",
                      background: "#fff",
                      justifyContent: "center",
                    }}
                  >
                    <div className="p-2 align-start initial-container">
                      {item.project_name}
                    </div>
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{
                      border: "none",
                      background: "#fff",
                      fontWeight: "500",
                    }}
                  >
                    {item.workspace_name}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{
                      border: "none",
                      background: "#fff",
                      fontWeight: "500",
                    }}
                  >
                    {item.workspce_desc}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{
                      border: "none",
                      display: "flex",
                      background: "#fff",
                      justifyContent: "center",
                    }}
                  >
                    <div className="p-2 align-start initial-container">
                      {item.lettersdata.map((employee, index) => (
                        <div
                          key={index}
                          className="initial"
                          style={{
                            marginLeft: "0.1rem",
                            background: item.colourcode,
                          }}
                        >
                          {employee.employee_first_letter}
                          <span className="employee-name m-0 p-1">
                            {employee.emp_name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{
                      border: "none",
                      background: "#fff",
                      fontWeight: "500",
                    }}
                  >
                    {item.teamsize}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", background: "#fff" }}
                  >
                    <Link
                      style={{ fontSize: ".8rem" }}
                      onClick={() =>
                        handleAddEmployeeModalOpen(
                          item.pk_work_id,
                          item.pk_project_id
                        )
                      }
                    >
                      Assign Employee
                    </Link>
                    &nbsp; &nbsp;
                    <Link to="/retailer/Task_management">
                      <img
                        onClick={() => {
                          sessionStorage.setItem(
                            "property",
                            JSON.stringify(item)
                          );
                        }}
                        src={require("../../../assets/images/arrow.png")}
                      />
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <AddWorkspaceadmin
        show={showaddworkspacetModal}
        closeModal={closeaddprojectModal}
        getWorkspacedata={getWorkspacedata}
      />

      <Adminassignemployeeworkspace
        isOpen={showAddEmployeeModal}
        onClose={handleAddEmployeeModalClose}
        selectedWorkspaceId={selectedWorkspaceId}
        getWorkspacedata={getWorkspacedata}
        selectedProject={selectedProject}
      />
    </div>
  );
};

export default Createworkspaceadmin;
