import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";

const Editblogs = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [content, setContent] = useState([]);
  const [Loaddata, setLoaddata] = useState([]);
  const [Editblogdata, setEditblogdata] = useState([]);
  const Editblogid = JSON.parse(sessionStorage.getItem("Editblogid"));
  const [postTempId, setPostTempId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [modalVideoSrc, setModalVideoSrc] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [currentTempId, setCurrentTempId] = useState(null);

  const handleOpenModal = (content, index) => {
    setModalContent(content);
    setCurrentIndex(index);
    setShowModal(true);
  };
  const handleOpenImageModal = (src, index, tempId) => {
    setModalImageSrc(src);
    setCurrentIndex(index);
    setCurrentTempId(tempId);
    setShowImageModal(true);
    console.log("Current Index in Image Modal:", index);
  };

  const handleOpenVideoModal = (src) => {
    setModalVideoSrc(src);
    setShowVideoModal(true);
  };
  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getStyle = (type) => {
    switch (type) {
      case "h1":
        return { fontSize: "2em" };
      case "h2":
        return { fontSize: "1.5em" };
      case "h3":
        return { fontSize: "1.17em" };
      case "h4":
        return { fontSize: "1em" };
      case "h5":
        return { fontSize: "0.83em" };
      case "h6":
        return { fontSize: "0.67em" };
      case "paragraph":
        return { fontSize: "1em" };
      case "orderedList":
      case "unorderedList":
        return { fontSize: "1em" };
      case "quote":
        return { fontSize: "1em", fontStyle: "italic" };
      case "checklist":
        return { fontSize: "1em" };
      case "code":
        return { fontSize: "1em" };
      case "link":
        return { fontSize: "1em", color: "blue", textDecoration: "underline" };

      default:
        return { fontSize: "1em" };
    }
  };
  const [editableContent, setEditableContent] = useState([
    ...content,
    ...Editblogdata,
  ]);

  useEffect(() => {
    console.log("EditableContent:", editableContent);
    setEditableContent([...content, ...Editblogdata]);
  }, [content, Editblogdata]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedContent = [...editableContent];
    updatedContent[index] = { ...updatedContent[index], [name]: value };
    setEditableContent(updatedContent);
  };
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const renderContent = () => {
    return editableContent.map((item, index) => {
      const strippedContent = stripHtmlTags(item.block_content || item.text);
      const sanitizedContent = DOMPurify.sanitize(
        item.block_content || item.text
      );

      let contentElement;

      switch (item.block_type || item.type) {
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
        case "paragraph":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle(item.block_type || item.type)}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "unorderedList":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <ul
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle("unorderedList")}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "numberedList":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <ol
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle("numberedList")}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "quote":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <blockquote
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle("quote")}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "checklist":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <div key={index} style={getStyle("checklist")}>
                <input
                  className="form-control"
                  type="checkbox"
                  name="text"
                  checked={item.text === "checked"} // Adjust based on your data
                  onChange={(e) => handleInputChange(index, e)}
                />
                <input
                  className="form-control"
                  type="text"
                  name="text"
                  value={item.text}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
            </div>
          );
          break;

        case "code":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <textarea
                name="text"
                value={item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("code")}
              />
              <SyntaxHighlighter
                customStyle={getStyle("code")}
                language={item.language}
                style={vs}
              >
                {item.text}
              </SyntaxHighlighter>
            </div>
          );
          break;

        case "link":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle(item.block_type || item.type)}
                target="_blank"
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
              {/* <a
                href={item.block_content || item.text}
                style={getStyle("link")}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.block_content || item.text}
              </a> */}
            </div>
          );
          break;

          case "uploadedImage":
            contentElement = (
              <div key={index} className="mt-2">
                <img
                  src={item.block_image}
                  alt="Uploaded Image"
                  style={{ width: "30vw" }}
                  onClick={() => handleOpenImageModal(item.block_image, index, item.temp_id)}
                />
              </div>
            );
            break;
        case "uploadedVideo":
          contentElement = (
            <div key={index} className="mt-2">
              <video
                controls
                src={item.block_image || item.src}
                style={{ width: "40vw" }}
                onClick={() =>
                  handleOpenVideoModal(item.block_image || item.src)
                }
              />
            </div>
          );
          break;

        default:
          contentElement = null;
      }

      return contentElement;
    });
  };

  const [formState, setFormState] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlefeatureImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            // Set the uploaded image URL in the formState
            setFormState((prevState) => ({
              ...prevState,
              imageUrl,
            }));

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const Geteditloadblog = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          p_post_id: Editblogid.post_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/LoadBlogForEditing`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);
      if (data.status === "0") {
        setLoaddata(data.data || []);
        setPostTempId(data.result[0].post_tempid);
        console.log(data.result[0].post_tempid);
        Geteditblog();
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const Geteditblog = async () => {
    if (!postTempId) return;
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          tempid: postTempId,
        }),
        redirect: "follow",
      };
      console.log(postTempId);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetBlogForEditing`,
        requestOptions
      );
      const data = await res.json();

      console.log("Geteditblog response:", data);

      if (data.status === "0") {
        setEditableContent(data.data[0].blogdetails);
        const blogData = data.data[0];

        setFormState({
          title: blogData.post_title,
          excerpt: blogData.post_excerpt,
          featuredImage: blogData.post_featured_image,
        });
      } else {
        console.error("Failed to fetch blog details:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    console.log("Loaddata:", Loaddata);
    console.log("PostTempId:", postTempId);
  }, [Loaddata, postTempId]);

  useEffect(() => {
    console.log("Editblogdata:", Editblogdata);
  }, [Editblogdata]);

  useEffect(() => {
    Geteditloadblog();
  }, []);
  useEffect(() => {
    if (postTempId) {
      Geteditblog();
    }
  }, [postTempId]);
  const [editorContent, setEditorContent] = useState(content);
  useEffect(() => {
    setEditorContent(content);
  }, [content]);
  const editorModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6] }],
        [{ font: [] }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["bold", "italic", "underline"],
        [{ align: [] }],
        [{ color: [] }],
        [{ align: [] }, { direction: "rtl" }],
        ["code-block"],
        ["clean"],
      ],
      handlers: {
        link: () => handleToolbarButtonClick("Link"),
        image: () => handleToolbarButtonClick("Image"),
        video: () => handleToolbarButtonClick("Video"),
      },
    },
  };
  const handleToolbarButtonClick = (name) => {
    setModalTitle(name);
    setModalShow(true);
  };
  const [modalTitle, setModalTitle] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalUploadedImageUrl, setModalUploadedImageUrl] = useState('');

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
  
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;
  
        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(filename1)}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );
  
          const result = await response.json();
  
          if (result.status === "success") {
            const imageUrl = result.file_url;
  
            // Update state for modal
            setModalUploadedImageUrl(imageUrl);
            setUploadSuccess(true); // Set the flag for successful upload
  
            // Notify user
            try {
              await swal({
                title: "Success!",
                text: `File uploaded successfully. URL: ${imageUrl}`,
                icon: "success",
                button: "OK",
              });
            } catch (swalError) {
              console.error("Swal error:", swalError);
            }
  
            console.log("Uploaded Image URL:", imageUrl);
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };
  
      reader.readAsArrayBuffer(file);
    }
  };
  

  console.log(uploadedImageUrl);
  const handleModalSave = async () => {
    console.log("handleModalSave called");
    console.log("currentIndex:", currentIndex);
    if (currentIndex >= 0 && currentIndex < editableContent.length) {
      const currentContent = editableContent[currentIndex];
      console.log("currentContent:", currentContent);

      const requestBody = {
        tempid: currentContent.temp_id,
        temp_post_id: postTempId,
        block_type: currentContent.block_type || "paragraph",
        block_content: modalContent || "NA",
        block_styling: "",
        block_image: uploadedImageUrl
          ? `<img src="${uploadedImageUrl}" alt="Uploaded Image" />`
          : "NA",
        block_order: currentIndex.toString(),
      };

      console.log("requestBody:", requestBody);
      try {
        const response = await fetch(
          "https://api.goseo.in/api/Seo/UploadBlockBlogs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();

        if (result.status === "0" && result.result[0].result === "Y") {
          swal({
            title: "Success!",
            text: result.result[0].message,
            icon: "success",
            button: "OK",
          });
          Geteditloadblog();
        } else {
          swal({
            title: "Error!",
            text: result.result[0].message || result.msg,
            icon: "error",
            button: "OK",
          });
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
      console.log("Save complete");
      setShowModal(false);
    } else {
      console.error("Invalid currentIndex:", currentIndex);
    }
  };
  const handleModalimageSave = async () => {
    console.log("handleModalSave called");
    console.log("currentIndex:", currentIndex);
    
    if (currentIndex >= 0 && currentIndex < editableContent.length) {
      const requestBody = {
        tempid: currentTempId,
        temp_post_id: postTempId,
        block_type: "uploadedImage",
        block_content: modalUploadedImageUrl,
        block_styling: "",
        block_image: modalUploadedImageUrl,
        block_order: currentIndex.toString(),
      };
  
      console.log("requestBody:", requestBody);
      try {
        const response = await fetch(
          "https://api.goseo.in/api/Seo/UploadBlockBlogs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();
  
        if (result.status === "0" && result.result[0].result === "Y") {
          swal({
            title: "Success!",
            text: result.result[0].message,
            icon: "success",
            button: "OK",
          });
          Geteditloadblog(); // Refresh content if necessary
          setEditableContent((prevContent) => [
            ...prevContent,
            {
              block_type: "uploadedImage",
              block_image: modalUploadedImageUrl,
              block_order: currentIndex.toString(),
            },
          ]);
        } else {
          swal({
            title: "Error!",
            text: result.result[0].message || result.msg,
            icon: "error",
            button: "OK",
          });
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
  
      console.log("Save complete");
      setShowImageModal(false); // Ensure this closes the correct modal
    } else {
      console.error("Invalid currentIndex:", currentIndex);
    }
  };
  
  console.log(postTempId);
  const Publish = async () => {
    try {
      const { title, excerpt, imageUrl } = formState;

      const raw = JSON.stringify({
        p_temp_post_id: postTempId,
        p_post_title: title,
        p_post_excerpt: excerpt,
        p_post_author: "1",
        p_post_featured_image: imageUrl || formState.featuredImage,
        p_status: "Published",
        p_product_name: "BillPunch",
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/publishBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const blogResult = data.result[0].result;
        const blogMessage = data.result[0].message;

        swal({
          title: "Success!",
          text: `${data.msg} - ${blogMessage}`,
          icon: "success",
          button: "OK",
        });
        Geteditblog();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: `Publish failed: ${error.message}`,
        icon: "error",
        button: "OK",
      });
    }
  };
  console.log(modalContent);
  return (
    <div className="m-3">
      <div className="row mt-0 pt-0 pb-0">
        <div className="col">
          <h5>Edit Blogs</h5>
        </div>
        <div className="col d-flex justify-content-end">
          <Button
            variant="info"
            size="sm"
            type="submit"
            style={{ height: "2rem" }}
            onClick={() => Publish()}
          >
            Save as Draft
          </Button>{" "}
          <Button
            style={{ background: "#fff", border: "none" }}
            size="sm"
            type="submit"
          ></Button>
          <Button
            variant="info"
            size="sm"
            type="submit"
            style={{ height: "2rem" }}
            onClick={() => Publish()}
          >
            Publish
          </Button>{" "}
          <Button
            style={{ background: "#fff", border: "none" }}
            size="sm"
            type="submit"
          ></Button>
          <Link to="/retailer/Blogs">
            <Button size="sm" variant="info">
              Back
            </Button>
          </Link>
        </div>
      </div>

      <Form className="row pb-0">
        <Form.Group className="col-md-3" controlId="formTitle">
          <Form.Control
            type="text"
            placeholder="Enter title"
            name="title"
            value={formState.title}
            onChange={handleChange}
          />{" "}
        </Form.Group>

        <Form.Group className="col-md-3" controlId="formExcerpt">
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add excerpt"
            name="excerpt"
            value={formState.excerpt}
            onChange={handleChange}
          />{" "}
        </Form.Group>

        <Form.Group
          className="col d-flex align-items-center"
          controlId="formImage"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label className="mb-0 mr-2">Feature image</Form.Label>
          {formState.featuredImage && (
            <img
              src={formState.featuredImage}
              alt="Featured"
              style={{
                width: "3rem",
                height: "3rem",
                borderRadius: "50%",
                objectFit: "cover",
                marginRight: "1rem",
              }}
            />
          )}
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handlefeatureImageUpload}
            className="flex-grow-1"
            style={{ maxWidth: "200px" }}
          />
        </Form.Group>
      </Form>
      <div className=" col-12 px-md-2">
        <div className="">{renderContent()}</div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Content Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactQuill
            modules={editorModules}
            value={modalContent}
            onChange={(content) => setModalContent(content)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={handleModalSave}>
            Save Changes
          </Button>
          <Button size="sm" variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Image Modal */}
      <Modal show={showImageModal} onHide={handleCloseImageModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalImageSrc}
            alt="Modal Image"
            style={{ width: "100%" }}
          />
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={handleModalimageSave}>
            Save Changes
          </Button>
          <Button size="sm" variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Video Modal */}
      <Modal show={showVideoModal} onHide={handleCloseVideoModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Video Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video controls src={modalVideoSrc} style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleCloseVideoModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Editblogs;
