import React from "react";

export default (state, action) => {
  console.log(action);
  switch (action.type) {
    case "SET_WALLET":
      return { ...state, wallet: action.payload };
    case "SET_TOKEN":
      return { ...state, token: action.payload };
    case "SET_MY_ACCOUNT_INFO":
      return {
        ...state,
        Profileinfo: action.payload,
      };
    case "SET_MY_ACCOUNT_INFO_DEVELOPER":
      return {
        ...state,
        myAccountInfo: action.payload,
      };
    case "SET_USER_INFO":
      return { ...state, user: action.payload };
    case "REMOVE_USER_INFO":
      return { ...state, user: action.payload };
    case "REMOVE_MY_ACCOUNT_INFO_DEVELOPER":
      return { ...state, myAccountInfo: action.payload };
      case "LOG_OUT_DEVELOPER":
        return { ...state, myAccountInfo: action.payload };
    case "SET_TRANSACTION_LOADER":
      return { ...state, transactionLoader: action.payload };
      case 'SET_ACCESS_DATA':
        return {
          ...state,
          accessData: action.payload
        };
        case 'SET_ACCESS_DATA_EMP':
          return {
            ...state,
            accessDataemp: action.payload
          };
    default:
      return state;
  }
};
