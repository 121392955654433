import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { Doughnut, Pie } from "react-chartjs-2";

import {
  excelMapping,
  ExportToExcel,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { BulkSalary, Holdsalarymodaladmin, ViewModalAdmin } from "./Modal";
import { Link } from "react-router-dom";

import Select from "react-select";
import { billpunchbasicauth } from "../../utils/Credentials";
export const columns = [
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "totalSalary",
    text: "Salary",
  },
  {
    dataField: "epf",
    text: "EPF",
  },
  {
    dataField: "bank_name",
    text: "Bank ",
  },
  {
    dataField: "bank_acc_no",
    text: "Acount",
  },
  {
    dataField: "bank_ifsc",
    text: "IFSC",
  },

  {
    dataField: "current_month",
    text: "Month",
  },
];

const Payrollprocesssalary = () => {
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
    height: "30px",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };
  const months = [
    { value: "January", name: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];
  const { password, username } = billpunchbasicauth;
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const [clickedRows, setClickedRows] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState({});
  const [generatedsalary, setGeneratedSalary] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [holdsalarymodal, setholdsalarymodal] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [clickedEmployee, setClickedEmployee] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const openBulkModal = () => setShowBulkModal(true);
  const Openholdsalarymodal = () => setholdsalarymodal(true);
  const Closeholdsalarymodal = () => setholdsalarymodal(false);
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleViewButtonClick = (employee) => {
    setClickedEmployee(employee);
    setViewModalShow(true);
  };
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const updateFilteredEmployeeList = (updatedList) => {
    setFilteredEmployeeList(updatedList);
  };
  const handleProcessAll = async (
    id,
    pk_emp_id,
    totalSalary,
    bank_acc_no,
    bank_ifsc,
    bank_name,
    mobile,
    name,
    current_month
  ) => {
    setTransactionLoaderState(true);
    let transactionMode = selectedServiceType[pk_emp_id];
    if (!transactionMode) {
      transactionMode = "IMPS";
    }

    if (clickedRows.includes(pk_emp_id)) {
      swal("Error", "Button already clicked for this row", "error");
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          id: id,
          firmid: user.firm_id,
          purpose: "SALARY",
          userid: pk_emp_id,
          amount: totalSalary,
          tpin: "1",
          serviceType: transactionMode,
          accNo: bank_acc_no,
          ifsc: bank_ifsc,
          bankName: bank_name,
          mobile: mobile,
          custName: name,
          month: current_month,
        }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/SalaryManagement/SalaryPayroll`,

        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");

        getEmployeegeneratedList();
      } else {
        swal("Error", data.msg, "error");
        getEmployeegeneratedList();
      }
      setTransactionLoaderState(false);
      setClickedRows((prevClickedRows) => [...prevClickedRows, pk_emp_id]);
    } catch (error) {
      console.error("API call failed", error);
      swal("API call failed", "An error occurred", "error");
    }
  };
  const getEmployeegeneratedList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        month: selectedMonth,
        firmid: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getGeneratedSalary`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0") {
        setGeneratedSalary(responseData.data);
      } else {
        setGeneratedSalary([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const [dashboard, setDashboard] = useState(null);
  useEffect(() => {
    const getEmployeegenerateddashboard = async () => {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          firmid: user.firm_id,
          month: selectedMonth,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetPayoutDashboard`,
          requestOptions
        );
        const responseData = await res.json();

        if (responseData.status === "0") {
          const dashboardData = responseData.data[0] || {};
          setDashboard(dashboardData);
        } else {
          setDashboard({ yourDataProperty: 0 });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };

    getEmployeegenerateddashboard();
  }, [selectedMonth]);
  useEffect(() => {
    console.log("employeeId updated:", employeeId);
  }, [employeeId]);
  useEffect(() => {
    getEmployeegeneratedList();
  }, [selectedMonth]);
  useEffect(() => {
    getDepartmentList();
  }, []);
  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: "16",
          weight: "bold",
        },
      },
    },
    legend: {
      display: false,
    },
  };
  const pieData = {
    labels: ["Total Generated", "Total Processed", "Total Unprocessed"],
    datasets: [
      {
        data: [
          parseFloat(dashboard?.totalSalary) || 0,
          parseFloat(dashboard?.totalProcessed) || 0,
          parseFloat(dashboard?.totalUnprocessed) || 0,
        ],
        backgroundColor: ["#FF8E8F", "#6196A6", "#7469B6"],
      },
    ],
  };
  const filteredData = generatedsalary
    .filter((e) =>
      selectedDepartment ? e.department === selectedDepartment : true
    )
    .filter((e) => {
      const fullName =
        `${e.name} ${e.emp_code} ${e.totalSalary} ${e.epf} ${e.bank_name} ${e.bank_acc_no} ${e.bank_ifsc} ${e.current_month} ${e.department}`.toLowerCase();
      return !searchQuery || fullName.includes(searchQuery.toLowerCase());
    });
  const handleSelectChange = (selectedOption, pk_emp_id) => {
    setSelectedServiceType({
      ...selectedServiceType,
      [pk_emp_id]: selectedOption.value,
    });
  };
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginationOptions = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
  ];
  const handleItemsPerPageChange = (selectedOption) => {
    setItemsPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const selectOptions = paginationOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));
  return (
    <div className="m-2">
      <ViewModalAdmin
        show={viewModalShow}
        handleClose={() => setViewModalShow(false)}
        employee={clickedEmployee}
      />
      <Holdsalarymodaladmin
        show={holdsalarymodal}
        handleClose={Closeholdsalarymodal}
      />
      <div className="row">
        <h5
          className="col px-3 pb-2"
          style={{
            color: "#4267B2",
          }}
        >
          Process Salary
        </h5>
        <div className="col d-flex mr-2  justify-content-end">
        {/* <div className="px-2">
            <Link to="/retailer/Payments">
              <Button
                className="btn-sm"
                style={{
                  cursor: "pointer",
                  border: "1px solid",
                  backgroundColor: "rgba(43, 193, 85, 0.1)",
                  color: "rgba(43, 193, 85, 1)",
                  bordercolor: "rgba(43, 193, 85, 1)",
                  borderRadius: ".4rem",
                }}
              >
               Payment
              </Button>
            </Link>
          </div> */}
          <div className="px-2">
            <Link to="/retailer/Payout_History">
              <Button
                className="btn-sm"
                style={{
                  cursor: "pointer",
                  border: "1px solid",
                  backgroundColor: "rgba(43, 193, 85, 0.1)",
                  color: "rgba(43, 193, 85, 1)",
                  bordercolor: "rgba(43, 193, 85, 1)",
                  borderRadius: ".4rem",
                }}
              >
                Payout History
              </Button>
            </Link>
          </div>
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `salary_ledger${now()}`,
                excelMapping(generatedsalary, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../../assets/images/exportexcel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>
      </div>
      <div
        className="row mx-2  "
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <div
          className="col-md-2 col-4 card   "
          style={{
            border: "1px solid #FF8E8F",
            borderRadius: ".4rem",
            height: "fit-content",
            boxShadow: "0 0 6px #FF8E8F",
          }}
        >
          <img
            alt=""
            style={{
              height: "2rem",
            }}
            src={require("../../../assets/images/pms/salary_report.svg")}
          />
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: ".9rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Total Generated
          </p>
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#FF8E8F",
            }}
          >
            {parseFloat(dashboard?.totalSalary) || 0}
          </p>
        </div>
        <div
          className="col-md-2 col-4 card   "
          style={{
            border: "1px solid #6196A6",
            borderRadius: ".4rem",
            height: "fit-content",
            boxShadow: "0 0 6px #6196A6",
          }}
        >
          <img
            alt=""
            style={{
              height: "2rem",
            }}
            src={require("../../../assets/images/pms/salary_report.svg")}
          />{" "}
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: ".9rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Total Processed
          </p>
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#6196A6",
            }}
          >
            {parseFloat(dashboard?.totalProcessed) || 0}
          </p>
        </div>
        <div
          className="col-md-2 col-4 card  "
          style={{
            border: "1px solid #7469B6",
            borderRadius: ".4rem",
            height: "fit-content",
            boxShadow: "0 0 6px #7469B6",
          }}
        >
          <img
            alt=""
            style={{
              height: "2rem",
            }}
            src={require("../../../assets/images/pms/salary_report.svg")}
          />{" "}
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: ".9rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Total Unprocessed
          </p>
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#7469B6",
            }}
          >
            {parseFloat(dashboard?.totalUnprocessed) || 0}
          </p>
        </div>

        <div className="col-md-4 col-5 " style={{ borderRadius: ".4rem" }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <Pie
              data={pieData}
              options={{
                ...pieOptions,
                maintainAspectRatio: false,
                responsive: true,
                height: 200,
              }}
            />
          </div>
        </div>
      </div>
      <div className="row  ">
        <div className="col">
          <div className="d-flex align-items-center px-2">
            <div className="px-2">
              <select
                className="px-2"
                style={{
                  width: "5rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                value={selectedYear}
                onChange={handleYearChange}
              >
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
            </div>

            <div className="px-2">
              <select
                style={{
                  width: "5rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="px-2">
              <select
                style={{
                  width: "9rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                value={selectedDepartment}
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);
                }}
              >
                <option value="">All Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* <Link to="/retailer/billTable">form</Link> */}
        <div className=" d-flex justify-content-end col">
          {accessData &&
            accessData.map(
              (module) =>
                module.data &&
                module.data.map((role) =>
                  role.role_name === "Generate Salary" ? (
                    role.access === "Y" ? (
                      <span key={role.pk_role_id}>
                        <Button
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            bordercolor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            border: "1px solid",
                            // height: "2.5rem",
                          }}
                          onClick={openBulkModal}
                        >
                          Generate Salary
                        </Button>
                      </span>
                    ) : (
                      <span key={role.pk_role_id}>
                        <button
                          className="btn btn-sm btn-primary d-flex justify-content-center"
                          style={{
                            color: "#fff",
                            // height: "2.5rem",
                            border: "none",
                            borderRadius: ".4rem",
                            display: "flex",
                            alignItems: "center",
                            fontSize: ".8rem",
                            backgroundColor: "gray",
                            cursor: "not-allowed",
                          }}
                          disabled
                        >
                          Generate Salary
                        </button>
                      </span>
                    )
                  ) : null
                )
            )}
        </div>
        <div className="px-2">
          <Button
            className="btn-sm"
            style={{
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
              border: "1px solid",
              // height: "2.5rem",
            }}
            onClick={Openholdsalarymodal}
          >
            Hold Salary
          </Button>
        </div>

        <div style={searchBarStyle} className=" col-md-2 col-4 mr-md-4 ">
          <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
          <input
            type="text"
            className="form-control "
            placeholder="Search"
            aria-label="Search"
            value={searchQuery}
            style={inputStyle}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <hr className="m-0 p-0" />
      <div style={{ width: "100%" }}>
        <div style={{ alignItems: "center" }}>
          <div style={{ flex: "1" }}></div>
        </div>
      </div>
      <div className=" scroll-inner admin-fix-width borderless-table ">
        <table
          className="my-table mx-2 transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <thead>
            <tr
              className="borderless-table text-center border-bottom"
              style={{ background: "white", color: "black" }}
            >
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Sr
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Emp Code
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Name
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Amount
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                EPF
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Account
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Month
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Pay Days
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Department
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Type
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Process
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Slip
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td
                  colSpan="12"
                  className="text-center m-3"
                  style={{ fontWeight: "400" }}
                >
                  {" "}
                  No Data Available
                </td>
              </tr>
            ) : (
              currentItems.map((e, ind) => (
                <tr
                  key={e.pk_emp_id}
                  className={`border-bottom ${
                    e.hold_status === "Y" ? "disabled-row-payroll" : ""
                  }`}
                >
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {ind + 1}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.emp_code || "NA"}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.name}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {twoDecimalPlaces(e.totalSalary)}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {twoDecimalPlaces(e.epf)}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    <div>
                      {e.bank_name}
                      <br />
                      {e.bank_acc_no}
                      <br />
                      {e.bank_ifsc}
                    </div>
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.current_month}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.pay_days}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.department}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    <Select
                      styles={{ width: "9rem" }}
                      className="px-1"
                      placeholder="Select Type"
                      value={{
                        value: selectedServiceType[e.pk_emp_id] || "IMPS",
                        label: selectedServiceType[e.pk_emp_id] || "IMPS",
                      }}
                      options={[
                        { value: "IMPS", label: "IMPS" },
                        { value: "NEFT", label: "NEFT" },
                      ]}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, e.pk_emp_id)
                      }
                      isDisabled={e.hold_status === "Y"}
                    />
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {accessData &&
                      accessData.map(
                        (module) =>
                          module.data &&
                          module.data.map((role) =>
                            role.role_name === "Proceed Salary" ? (
                              role.access === "Y" ? (
                                <span key={role.pk_role_id}>
                                  {e.status === "Y" ? (
                                    <div>
                                      <Button
                                        className="d-flex align-items-center btn-success"
                                        style={{
                                          height: "2rem",
                                          fontSize: "1rem",
                                          borderRadius: ".4rem",
                                          fontSize: ".8rem",
                                        }}
                                        disabled={true}
                                      >
                                        Processed
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>
                                      <Button
                                        className="d-flex align-items-center"
                                        style={{
                                          height: "2rem",
                                          fontSize: "1rem",
                                          borderRadius: ".4rem",
                                          fontSize: ".7rem",
                                        }}
                                        onClick={() =>
                                          handleProcessAll(
                                            e.id,
                                            e.pk_emp_id,
                                            e.totalSalary,
                                            e.bank_acc_no,
                                            e.bank_ifsc,
                                            e.bank_name,
                                            e.mobile,
                                            e.name,
                                            e.current_month
                                          )
                                        }
                                        disabled={e.hold_status === "Y"}
                                      >
                                        Process
                                      </Button>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span key={role.pk_role_id}>
                                  {e.status === "Y" ? (
                                    <div>
                                      <button
                                        className="btn btn-sm btn-primary d-flex justify-content-center"
                                        style={{
                                          color: "#fff",
                                          height: "2rem",
                                          border: "none",
                                          borderRadius: ".4rem",
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: ".8rem",
                                          backgroundColor: "gray",
                                          cursor: "not-allowed",
                                        }}
                                        disabled
                                      >
                                        Processed
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      <button
                                        className="btn btn-sm btn-primary d-flex justify-content-center"
                                        style={{
                                          color: "#fff",
                                          height: "2rem",
                                          border: "none",
                                          borderRadius: ".4rem",
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: ".8rem",
                                          backgroundColor: "gray",
                                          cursor: "not-allowed",
                                        }}
                                        disabled
                                      >
                                        Process
                                      </button>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : null
                          )
                      )}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <Link
                        style={{ marginRight: "5px" }}
                        className="tooltip-container"
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/slip.svg")}
                          onClick={() => handleViewButtonClick(e)}
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="m-3 ">
          <label>Items per page:</label>
          <div style={{ width: "6rem" }}>
            <Select
              options={selectOptions}
              value={{ value: itemsPerPage, label: `${itemsPerPage}` }}
              onChange={handleItemsPerPageChange}
              style={{ width: "4rem" }}
            />
          </div>
        </div>
        <BulkSalary
          showBulkModal={showBulkModal}
          setShowBulkModal={setShowBulkModal}
          filteredEmployeeList={filteredEmployeeList}
          updateFilteredEmployeeList={updateFilteredEmployeeList}
          getEmployeegeneratedList={getEmployeegeneratedList}
          handleClose={() => setShowBulkModal(false)}
        />
      </div>
    </div>
  );
};
export default Payrollprocesssalary;
