import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { AddProjectemployeeModal } from "./Modal";

const Createworkemployee = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    workspaceName: "",
    workspaceDesc: "",
    teamSize: "",
    workspaceDescription: "",
    colorCode: "",
    projectId: "",
  });

  const { password, username } = billpunchbasicauth;
  const history = useHistory();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projects, setProjects] = useState([]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId" && value === "createProject") {
      openaddprojectModal();
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleColorSelect = (color) => {
    setFormData({
      ...formData,
      colorCode: color,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 1 && formData.workspaceName.trim() === "") {
      swal("Error", "Please enter workspace name.", "error");
      return;
    } else if (step === 2 && formData.colorCode === "") {
      swal("Error", "Please select an avatar color.", "error");
      return;
    } else if (step === 3 && formData.projectId === "") {
      swal("Error", "Please select a project.", "error");
      return;
    } else if (step === 4 && formData.teamSize === "") {
      swal("Error", "Please select team size.", "error");
      return;
    } else if (step === 5 && formData.workspaceDescription.trim() === "") {
      swal("Error", "Please enter workspace description.", "error");
      return;
    }

    if (step === 5) {
      console.log("Form submission started.");
      try {
        setStep(6);
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          firmid: user.firm_id,
          name: formData.workspaceName,
          desc: formData.workspaceDescription,
          empid: user.userid,
          ownername: user.username,
          teamsize: formData.teamSize,
          colorcode: formData.colorCode,
          project_id: formData.projectId,
        });

        console.log("Request sending:", raw);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        console.log("Sending data to API.");
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddWorkSpace`,
          requestOptions
        );
        console.log("API URL");
        const data = await res.json();
        console.log("API response:", data);
        if (data.status === "1") {
          swal("Error", data.result, "error");
          setTransactionLoaderState(false);
        } else {
          swal("Success", data.result, "success");
          setTransactionLoaderState(false);
          history.push("/retailer/WorkDashboardnew");
        }
        console.log("Form submission completed.");
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      nextStep();
    }
  };

  const fetchProject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetProjectByEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProjects(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const [showaddprojectModal, setshowaddprojectModal] = useState(false);
  const openaddprojectModal = () => {
    setshowaddprojectModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddprojectModal(false);
  };

  return (
    <div className="container">
      <AddProjectemployeeModal
        show={showaddprojectModal}
        closeModal={closeaddprojectModal}
        getProjects={fetchProject}
      />
      <div className="row justify-content-center mt-5">
        <div className="col-md-7">
          <div className="card">
            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  {/* Progress bar */}
                  <div className="progress mb-4">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${(step - 1) * 20}%` }}
                      aria-valuenow={(step - 1) * 20}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

              {/* Steps rendering */}
              <form onSubmit={handleSubmit}>
                {step === 1 && (
                  <>
                    <h2 className="text-center mb-4">
                      Step 1: Name Your Workspace
                    </h2>
                    <div className="form-group">
                      <textarea
                        type="textarea"
                        name="workspaceName"
                        className="form-control"
                        value={formData.workspaceName}
                        onChange={handleChange}
                        placeholder="Enter workspace name"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Next
                    </button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <h2 className="text-center mb-4">
                      Step 2: Create Your Avatar
                    </h2>
                    <div className="row">
                      <div className="col-3 avatar-container">
                        <div
                          className="avatar"
                          style={{
                            backgroundColor: formData.colorCode || "#FDBF60",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            width: "100px",
                            height: "100px",
                            fontSize: "2rem",
                          }}
                        >
                          {formData.workspaceName.trim().charAt(0)}
                        </div>
                      </div>
                      <div className="col color-palette d-flex align-items-center">
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#58A399" }}
                          onClick={() => handleColorSelect("#58A399")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#ACE2E1" }}
                          onClick={() => handleColorSelect("#ACE2E1")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#FF8E8F" }}
                          onClick={() => handleColorSelect("#FF8E8F")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#E1AFD1" }}
                          onClick={() => handleColorSelect("#E1AFD1")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#C5EBAA" }}
                          onClick={() => handleColorSelect("#C5EBAA")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#B7C9F2" }}
                          onClick={() => handleColorSelect("#B7C9F2")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#F2E18C" }}
                          onClick={() => handleColorSelect("#F2E18C")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#FDBF60" }}
                          onClick={() => handleColorSelect("#FDBF60")}
                        ></div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Next
                    </button></div>
                  </>
                )}
                {step === 3 && (
                  <>
                    <h2 className="text-center mb-4">
                      Step 3: Assign Project
                    </h2>
                    <div className="form-group">
                      <select
                        name="projectId"
                        className="form-control"
                        value={formData.projectId}
                        onChange={handleChange}
                      >
                        <option value="">Select a Project</option>
                        <option value="createProject">+ Create Project</option>
                        {projects.map((project) => (
                          <option key={project.pk_project_id} value={project.pk_project_id}>
                            {project.project_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Next
                    </button></div>
                  </>
                )}
                {step === 4 && (
                  <>
                    <h2 className="text-center mb-4">Step 4: Team Size</h2>
                    <div className="form-group">
                      <select
                        name="teamSize"
                        className="form-control"
                        value={formData.teamSize}
                        onChange={handleChange}
                      >
                        <option value="">Select team size</option>
                        <option value="1-5">1-5</option>
                        <option value="6-10">6-10</option>
                        <option value="11-20">11-20</option>
                        <option value="21-50">21-50</option>
                        <option value="51-100">51-100</option>
                        <option value="101+">101+</option>
                      </select>
                    </div>
                    <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Next
                    </button></div>
                  </>
                )}
                {step === 5 && (
                  <>
                    <h2 className="text-center mb-4">
                      Step 5: Workspace Description
                    </h2>
                    <div className="form-group">
                      <textarea
                        type="textarea"
                        name="workspaceDescription"
                        className="form-control"
                        value={formData.workspaceDescription}
                        onChange={handleChange}
                        placeholder="Enter workspace description"
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button></div>
                  </>
                )}
                {step === 6 && (
                  <>
                    <h2 className="text-center mb-4">Submission In Progress</h2>
                    <div className="text-center">
                      <p>Please wait while your workspace is being created.</p>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createworkemployee;
