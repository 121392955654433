import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Slider from "./slider";
import Successcard from "./Successcard";
import TalkExpert from "./TalkExpert";
import Main from "./Main";
import Nabvar from "./Navbar";
import GlobalCostumer from "./GlobalCostumer";
import ReviewCard from "./Reviewcard";
import Solution from "./Solution";
import Synchronize from "./Synchronize";
import ScheduleDemo from "./Scheduledemo";
import Plans from "./Plans";
import Successcardslider from "./Succescardslider";
import trackPageView from "../../../trackPageView";
import ActiveUserCount from "../../../ActiveUserCount";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";

const LandingPage = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  useEffect(() => {
    trackPageView();
    getLocation();
  
  }, []);
  const [placeName, setPlaceName] = useState("");
  const [latitude, setLatitude] = useState(null);
  const { password, username } = billpunchbasicauth;
  const [longitude, setLongitude] = useState(null);
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showPosition = async (position) => {
    console.log("Received position data:", position);
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    console.log("Latitude:", position.coords.latitude);
    console.log("Longitude:", position.coords.longitude);
  
    setTransactionLoaderState(true);
  
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
      );
      const data = await response.json();
      console.log("Geocoding Response:", data);
      
      if (data && data.address && data.address.country) {
        setPlaceName(data.address.country);
      } else {
        console.log("No country found in geocoding response");
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  
  const Getarrear = async () => {
    if (!latitude || !longitude || !placeName || placeName === "0") {
      console.log("Latitude, longitude, or country is not available. Aborting API call.");
      return;
    }
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        latitude: latitude||"NA",
        longitude: longitude||"NA",
        country: placeName,
        product: "BillPunch",
      });
console.log(raw);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/InsertProductLocation`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (placeName && placeName !== "0") {
      console.log("Calling Getarrear with country:", placeName);
      Getarrear();
    }
  }, [ placeName]);
  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  useEffect(() => {
    const sessionId = generateUUID();
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has('sessionid')) {
      urlParams.set('sessionid', sessionId);

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;

      window.history.replaceState(null, '', newUrl);
    }
  }, []);
  return (
    <>
   
      <ActiveUserCount />
      <Nabvar />
      <Main />
      {/* <GlobalCostumer /> */}
      <Slider />
      <Solution />
      <TalkExpert />
      <Synchronize />
      <Successcard />
      <ReviewCard />
      <ScheduleDemo />
      {/* <Plans/> */}
      <Footer />
      {/* <Successcardslider/> */}
    </>
  );
};

export default LandingPage;
