import React, { useState, useEffect, useContext } from "react";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { Badge } from "react-bootstrap";
import img1 from "../../../assets/images/Add.png";
import img2 from "../../../assets/images/user.png";
import Nabvar from "./Navbar";
import Footer from "./footer";
const Blog = () => {
  const [visibleCards, setVisibleCards] = useState(3);
  const [cards, setCards] = useState([]);
  const [transitioning, setTransitioning] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const tags = [
    "Business",
    "Corporate",
    "Finance",
    "Invest",
    "Planning",
    "Sport",
    "News",
  ];
  const authors = [
    {
      imageSrc: img1,
      title: "Explore expert tips for a stress-free cross-country transition",
      author: "John Nielson",
    },
    {
      imageSrc: img2,
      title: "Discover unique alternatives to traditional memory boxes.",
      author: "John Nielson",
    },
    {
      imageSrc: img1,
      title: "Explore expert tips for a stress-free cross-country transition",
      author: "John Nielson",
    },
    {
      imageSrc: img2,
      title: "Discover unique alternatives to traditional memory boxes.",
      author: "John Nielson",
    },
    {
      imageSrc: img1,
      title: "Explore expert tips for a stress-free cross-country transition",
      author: "John Nielson",
    },
    {
      imageSrc: img2,
      title: "Discover unique alternatives to traditional memory boxes.",
      author: "John Nielson",
    },
  ];

  const { setTransactionLoaderState } = useContext(GlobalContext);

  const Getproductblogs = async () => {
    setTransactionLoaderState(true);
    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetProductBlogs`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_name: "billpunch" }),
          redirect: "follow",
        }
      );
      const data = await response.json();
      if (data.status === "0") {
        setCards(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getproductblogs();
  }, []);

  const nextSlide = () => {
    const lastIndex = cards.length - 1;
    setCurrentIndex((prevIndex) =>
      prevIndex === lastIndex ? 0 : prevIndex + 1
    );
    setTransitioning(true);
    setTimeout(() => setTransitioning(false), 100);
  };

  const prevSlide = () => {
    const lastIndex = cards.length - 1;
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? lastIndex : prevIndex - 1
    );
    setTransitioning(true);
    setTimeout(() => setTransitioning(false), 100);
  };
  const endIndex = currentIndex + visibleCards;
  const repeatedCards =
    cards.length > visibleCards ? [...cards, ...cards] : cards;
  const displayedCards = repeatedCards.slice(currentIndex, endIndex);

  return (
    <>
      <Nabvar />
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row " style={{ overflow: "hidden", height: "auto" }}>
            <div
              className="col-md-9 col-12 m-0 p-0 "
              style={{ marginRight: "2rem" }}
            >
              <div className="" style={{ marginRight: "2rem" }}>
                <div className="row d-flex justify-content-between align-items-end px-2  pt-2 pb-md-2 pb-1">
                  <div className="col-6 d-flex justify-content-start px-md-3 px-2">
                    <h5
                      style={{
                        fontWeight: "500",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Blogs
                    </h5>
                  </div>
                  <div className="col-6 px-md-3 px-2 d-flex justify-content-end">
                    <span
                      className="cursor-pointer mx-md-3 mx-2"
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f9",
                        height: "2rem",
                        width: "2rem",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={prevSlide}
                    >
                      <span
                        className="p-0 px-2"
                        style={{ fontSize: "1.3rem", color: "#787878" }}
                      >
                        {"<"}
                      </span>
                    </span>
                    <span
                      className="cursor-pointer mx-md-"
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "#f2f4f9",
                        height: "2rem",
                        width: "2rem",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={nextSlide}
                    >
                      <span
                        className="p-0 px-2"
                        style={{ fontSize: "1.3rem", color: "#252525" }}
                      >
                        {">"}
                      </span>
                    </span>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  {displayedCards.map((card, index) => (
                    <div className=""
                      key={index}
                      style={{
                        backgroundColor: "#fff",
                        margin: "10px",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        height: "100%",
                        flex: "1 1 300px",
                        transition: "opacity 0.5s ease-in-out",
                      }}
                    >
                      <img
                        src={card.post_featured_image}
                        alt={card.post_title}
                        style={{
                          maxWidth: "100%",
                          marginBottom: "1rem",
                          height: "40vh",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        className="card-content"
                        style={{
                          padding: "0 10px",
                          height: "15vvh",
                          overflow: "hidden",
                        }}
                      >
                        <div className="row p-0 m-0">
                          <div className="col-2 p-0">
                            <img
                              src={card.post_featured_image}
                              alt="Author"
                              style={{
                                width: "2.5rem",
                                height: "2.5rem",
                                borderRadius: "50%",
                                marginRight: "1rem",
                              }}
                            />
                          </div>{" "}
                          <div className="col d-flex align-items-center p-0">
                            <p
                              className="mx-2 mb-1"
                              style={{
                                textAlign: "start",
                                color: "#6d6d6d",
                                fontFamily: "Roboto, sans-serif",
                                fontSize: ".9rem",
                                fontWeight: "600",
                              }}
                            >
                              Author Name{" "}
                            </p>
                          </div>
                        </div>

                       <div style={{height:"10vh"}}> <h6
                          className="mt-2"
                          style={{
                            textAlign: "start",
                            color: "#3E3232",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {card.post_title}
                        </h6></div>

                        <p
                          style={{
                            textAlign: "start",
                            color: "#3E3232",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp: 4,
                            overflow: "hidden", 
                            textOverflow: "ellipsis",
                          }}
                        >
                          {card.post_excerpt}
                        </p>
                        <p
                          style={{
                            textAlign: "start",
                            color: "#6d6d6d",
                            fontFamily: "Roboto, sans-serif",
                            fontSize: ".9rem",
                            fontWeight: "600",
                          }}
                        >
                          {new Date(card.created_at).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12 p-md-0 p-5 ">
              <div className="d-flex flex-column justify-content-start align-items-center mt-5 ">
                <h6
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  Related Tags
                </h6>
                <div
                  className="d-flex flex-wrap"
                  style={{ textAlign: "start", justifyContent: "center" }}
                >
                  {tags.map((tag, index) => (
                    <Badge
                      key={index}
                      className="btn btn-outline-secondary mx-2 mt-2"
                      style={{ borderRadius: ".4rem" }}
                    >
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>

              <div className="d-flex flex-column justify-content-start align-items-center mt-4">
                {authors.map((author, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-3"
                    style={{ textAlign: "start" }}
                  >
                    <img
                      src={author.imageSrc}
                      alt="Author"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      }}
                    />
                    <div className="ms-3">
                      <p style={{ fontSize: ".8rem", margin: 0 }}>
                        {author.title}
                      </p>
                      <p
                        style={{
                          fontSize: ".7rem",
                          color: "#787878",
                          margin: 0,
                        }}
                      >
                        {author.author}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Blog;
