import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Button, InputGroup, Overlay, Popover } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { uniqueID2 } from "../../utils/utilFuctions";
import {
  FaBirthdayCake,
  FaEnvelope,
  FaMapMarkerAlt,
  FaUser,
} from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

const Adminsetting = () => {
  const [activeTab, setActiveTab] = useState("tab2");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <h4 className="pt-2 pb-2 px-2">Settings</h4>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
              style={activeTab === "tab2" ? { border: "none" } : {}}
              onClick={() => handleTabClick("tab2")}
            >
              Profile
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              Key
            </button>
          </li>
        </ul>
        <div className="tab-content p-3">
          {activeTab === "tab1" && <Key />}
          {activeTab === "tab2" && <Profile />}
        </div>
      </div>
    </>
  );
};
const Key = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const location = useLocation();
  const [keyId, setKeyId] = useState("");
  const [keySecret, setKeySecret] = useState("");
  const [showKeySecret, setShowKeySecret] = useState(false);

  const Getkey = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetGeneratedKeys`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const { keyid, keysecret } = data.data[0];
        setKeyId(keyid);
        setKeySecret(keysecret);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkey();
  }, []);

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,Key ID,Key Secret\n${keyId},${keySecret}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "key_id_and_key_secret.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const login = () => {
    const callbackUrl = `${window.location.origin}/identity`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
    window.location.href = redirectUrl;
  };
  return (
    <>
      <div
        className="card p-2 col-md-5 col-10"
        style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", height: "63%" }}
      >
        <div style={{ width: "100%", textAlign: "center", paddingTop: "20px" }}>
          <h5 className="" style={{ color: "#000" }}>
            Key ID & Key Secret
          </h5>
        </div>
        <div className="d-flex justify-content-center mt-md-3 mt-4">
          <div>
            <Form className="mt-4">
              <Form.Group controlId="formKeyId">
                <Form.Label>Key ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Key ID"
                  value={keyId}
                  onChange={(e) => setKeyId(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formKeySecret">
                <Form.Label>Key Secret</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showKeySecret ? "text" : "password"}
                    placeholder="Enter Key Secret"
                    value={keySecret}
                    onChange={(e) => setKeySecret(e.target.value)}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowKeySecret(!showKeySecret)}
                  >
                    {showKeySecret ? "Hide" : "Show"}
                  </Button>
                </InputGroup>
                <Link
                  variant="secondary"
                  onClick={downloadCSV}
                  className="mt-2"
                >
                  Download ID & Secret
                </Link>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
const Profile = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [data, setData] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);
  const [image, setImage] = useState(null);
  const target = useRef(null);
  const [showOverlay1, setShowOverlay1] = useState(false);

  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        empid: user.userid,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeProfileDetails`,
        requestOptions
      );
      const apiData = await response.json();
      if (apiData.status === "0") {
        setData(apiData.data[0]);
      } else {
        throw new Error(apiData.msg); // Throw error if API status is not 0
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      swal("Error", "Failed to fetch profile data", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!image) {
      return;
    }

    const newImageName = `profile_image_${uniqueID2()}`;

    if (image.size > 2 * 1000000) {
      swal("Invalid", "Image Size should be less than 2 MB", "error");
      return;
    }

    try {
      const byteArray = await convertToByteArray(image);

      const raw = JSON.stringify({
        firmid: user.firm_id,
        userid: user.userid,
        image_type: "profile_image",
        file_type: image.type,
        image_data: byteArray,
      });

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ImageUpload`,
        requestOptions
      );
      const result = await response.json();

      console.log("API Response:", result);

      if (result.status === "0") {
        swal("Success", result.msg, "success").then(() => {
          getEmployeeList();
        });
      } else {
        swal("Error", result.msg, "error");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      swal("Error", "Failed to upload image", "error");
    } finally {
      setTransactionLoaderState(false);
      setShowOverlay(false);
    }
  };

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
    });
  };
  return (
    <div className=" m-2 " style={{ background: "#fff" }}>
      <div className="row ">
        <div
          className="m-1 p-3 col-5 card mx-3"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="row">
            <div className="col d-flex justify-content-center" ref={target}>
              {data.image_url ? (
                <img
                  src={data.image_url}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  onClick={() => setShowOverlay1(true)}
                />
              ) : (
                <FaUser style={{ color: "teal", fontSize: "2.5rem" }} />
              )}
            </div>
            <div className="col-1">
              <CiEdit
                onClick={() => setShowOverlay(!showOverlay)}
                style={{ cursor: "pointer", fontSize: "1.5rem", color: "grey" }}
              />
            </div>
          </div>

          <h4 className="d-flex justify-content-center mt-2">
            {data.employee_name || "NA"}
          </h4>

          <h6 className="d-flex justify-content-center">
            {data.designation || "NA"}
          </h6>
          <hr />
          <p style={{ fontSize: "1rem" }}>Basic Info</p>
          <span className="menu-icon m-2">
            <FaEnvelope style={{ color: "red", marginRight: "5px" }} />
            <p style={{ display: "inline", fontSize: "1rem", color: "black" }}>
              {data.email || "NA"}
            </p>
          </span>
          <span className="menu-icon m-2">
            <FaBirthdayCake style={{ color: "red", marginRight: "5px" }} />
            <p style={{ display: "inline", fontSize: "1rem", color: "black" }}>
              {data.dob ? formatDate(data.dob) : "NA"}
            </p>
          </span>
          <span className="menu-icon m-2">
           
            <p style={{ display: "", fontSize: ".95rem", color: "black" }}>
            <FaMapMarkerAlt style={{ color: "red", marginRight: "5px" }} />  {data.address || "NA"}
            </p>
          </span>
        </div>
        <div
          className="col m-1 p-0 card mx-3"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="row">
            <div className="col">
              <h5>Personal Information</h5>
            </div>
          </div>
          <hr className="m-0" />
          <div className="row p-0 m-0 mt-1 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Employee code
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.empcode || "NA"}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Employee Mobile
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.mobile || "NA"}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Department
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.department || "NA"}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Employee type
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.emptype || "NA"}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Joining Date
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {new Date(data.joiningdate).toLocaleDateString() || "NA"}
            </p>
          </div>
          <hr className="m-3" />
          <div className="row m-0 p-0 ">
            <div className="col">
              <h5>Bank Information</h5>
            </div>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Bank Name
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.bankaccno || "NA"}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Bank IFSC
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.bankifsc || "NA"}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              Bank Account
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.bankname || "NA"}
            </p>
          </div>
        </div>
      </div>

      <Overlay
        target={target.current}
        show={showOverlay}
        placement="bottom"
        rootClose
        onHide={() => setShowOverlay(false)}
      >
        <Popover id="popover-basic" style={{ background: "white" }}>
          <Popover.Title style={{ background: "white" }} as="h3">
            Upload Image
          </Popover.Title>
          <Popover.Content style={{ background: "white" }}>
            <Form>
              <Form.Group>
                <Form.File
                  id="imageUpload"
                  label="Choose image"
                  onChange={handleImageChange}
                />
              </Form.Group>
              <Button
                className="btn-sm"
                variant="primary"
                onClick={handleImageUpload}
              >
                Upload
              </Button>
            </Form>
          </Popover.Content>
        </Popover>
      </Overlay>

      {showOverlay1 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={() => setShowOverlay1(false)}
        >
          <img
            src={data.image_url}
            alt="Profile Overlay"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "10px",
            }}
            onLoad={() => console.log("Image loaded")}
            onError={() => console.log("Error loading image")}
          />
        </div>
      )}
    </div>
  );
};
export default Adminsetting;
