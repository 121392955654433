import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { IoIosSend } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const Blogs = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [blogdata, setBlogData] = useState([]);

  const Getproductblogs = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          product_name: "billpunch",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetProductBlogs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setBlogData(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getproductblogs();
  }, []);

  return (
    <div className="m-3">
      <h4>Blog Posts</h4>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B" }}
            >
              ID
            </th>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B", width: "20vw" }}
            >
              Title
            </th>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B", width: "20vw" }}
            >
              Excerpt
            </th>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B" }}
            >
              Author
            </th>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B" }}
            >
              Created At
            </th>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B" }}
            >
              Status
            </th>
            <th
              className="text-center"
              style={{ background: "#A1D6B2", color: "#16325B" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {blogdata.map((blog) => (
            <tr key={blog.post_id}>
              <td className="text-center align-middle">{blog.post_id}</td>
              <td className="text-center align-middle font-weight-bold">
                {blog.post_title}
              </td>
              <td className="text-center align-middle">
                {blog.post_excerpt.split(" ").slice(0, 15).join(" ")}...
              </td>
              <td className="text-center align-middle">{blog.post_author}</td>
              <td className="text-center align-middle">
                {new Date(blog.created_at).toLocaleDateString()}
              </td>
              <td className="text-center align-middle">{blog.status}</td>
              <td className="text-center align-middle">
                <div className="d-flex justify-content-center gap-3">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
                  >
                    <Link
                      to="/retailer/Edit_blogs"
                      onClick={() => {
                        sessionStorage.setItem(
                          "Editblogid",
                          JSON.stringify(blog)
                        );
                      }}
                    >
                      <MdEdit style={{ fontSize: "1.3rem", color: "green" }} />
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                  >
                    <FaTrash style={{ fontSize: "1rem", color: "red" }} />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Publish</Tooltip>}
                  >
                    <IoIosSend
                      style={{ fontSize: "1.3rem", color: "#7695FF" }}
                    />
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Blogs;
