import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Successcardslider = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="dot-click-slider" style={{background:"#f2f4f2"}}>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item>
          <div
            className=" card m-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                  alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon1.png")}
                />
              </span>{" "}
              &nbsp; Centralized Platform
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              Simplifies by centralizing all functions into one platform. With
              interactive dashboards and self-service options, it streamlines
              processes, saving time and costs for businesses.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className=" card m-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                  alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon2.png")}
                />
              </span>{" "}
              &nbsp; User Experience
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              Enhances your experience by providing intuitive interfaces and
              seamless navigation. With user-centric design and interactive
              features, we ensures efficient experience for all users.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className=" card m-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                  alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon5.png")}
                />
              </span>{" "}
              &nbsp; Data Security
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              We ensures data security with robust measures . Our platform
              maintains strict access controls, guaranteeing confidentiality and
              compliance with privacy regulations.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className=" card m-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                  alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon3.png")}
                />
              </span>{" "}
              &nbsp; Time Efficiency
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              This maximizes time efficiency through streamlined processes and
              Effortless interfaces. With user-friendly features and optimizes
              productivity, saving time for businesses and employees alike.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          {" "}
          <div
            className=" card m-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                  alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon4.png")}
                />
              </span>{" "}
              &nbsp; Reporting and Analytics
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              Our comprehensive reporting empowers businesses with valuable
              insights and customizable, real-time data visualization for
              enhanced strategic planning and performance.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>

      <Carousel.Item>
        {" "}
        <div
          className=" card m-5 p-2 "
          style={{
            border: "1px solid #C8DEFF",
            borderRadius: ".5rem",
          }}
        >
          <p className="" style={{ fontSize: "1rem", color: "#000" }}>
            <span>
              {" "}
              <img
                alt="Billpunch"
                style={{ height: "2rem" }}
                src={require("../../../assets/images/cardicon6.png")}
              />
            </span>{" "}
            &nbsp; Efficient Record-Keeping
          </p>
          <p style={{ fontSize: "", color: "#000" }}>
            We ensures streamlined processes for easy access and management of
            essential data. With user-friendly interfaces, we optimize
            efficiency, facilitating seamless operations.
          </p>
        </div>
      </Carousel.Item>
      {/* <div className="dot-indicators">
        {[...Array(6).keys()].map((i) => (
          <span
            key={i}
            onClick={() => setIndex(i)}
            className={i === index ? "active" : ""}
          ></span>
        ))}
      </div> */}
    </div>
  );
};

export default Successcardslider;
