import React, { useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
const GlobalCostumer = () => {

  return (
    <>
      <div>
        <div className="mt-5 d-flex justify-content-center">
          <p className="heading" style={{  color: "black", fontWeight: "500" }}>
           
            The Empowering HR Solutions, Delivering
            <br /> 
            <span className="d-flex justify-content-center">Precision in &nbsp;
            <span style={{ color: "#0085ff" }}> Payroll Management</span></span>{" "}
          </p>
        </div>
        <div className="d-flex mr-5 pr-5 justify-content-end">
          {" "}
          <img
            className="d-flex mr-5 pr-5"
            alt="Billpunch"
            style={{ height: "rem" }}
            src={require("../../../assets/images/secontback.svg")}
          />
        </div>
        <div className="mx-5 px-5">
          {" "}
          <img
            className="mx-5 px-5"
            alt="Billpunch"
            style={{ height: "rem" }}
            src={require("../../../assets/images/2ndemelent.svg")}
          />
        </div>
      </div>
    
    </>
  );
};

export default GlobalCostumer;

