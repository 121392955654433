import React, { useContext, useEffect, useState } from "react";
import {
  FaParagraph,
  FaList,
  FaListOl,
  FaImage,
  FaBold,
  FaUnderline,
  FaItalic,
} from "react-icons/fa";
import swal from "sweetalert";
import { RiDoubleQuotesL } from "react-icons/ri";
import {
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuHeading4,
  LuHeading5,
  LuHeading6,
} from "react-icons/lu";
import { FaListCheck } from "react-icons/fa6";
import { IoIosImages, IoMdCode, IoMdLink } from "react-icons/io";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
const Customblog = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedIcon, setSelectedIcon] = useState("h1");
  const [textContent, setTextContent] = useState("");
  const [content, setContent] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [images, setImages] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [codeContent, setCodeContent] = useState("");
  const [codeLanguage, setCodeLanguage] = useState("javascript");
  const [linkUrl, setLinkUrl] = useState("");
  const [message, setMessage] = useState("");
  const [tempPostId, setTempPostId] = useState("");
  const [styles, setStyles] = useState({
    bold: false,
    underline: false,
    italic: false,
  });
  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };
  useEffect(() => {
    const generatedUUID = generateUUID();
    setTempPostId(generatedUUID);
  }, []);
  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;
            setContent((prevContent) => [
              ...prevContent,
              { type: "uploadedImage", src: imageUrl },
            ]);

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            setMessage(`Error: ${result.message}`);
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          setMessage(`Upload failed: ${error.message}`);
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleVideoUpload = async (event) => {
    const files = Array.from(event.target.files);

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename2 = file.name;
        const fileType = file.type;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename2
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const videoUrl = result.file_url;
            setContent((prevContent) => [
              ...prevContent,
              { type: "uploadedVideo", src: videoUrl },
            ]);

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${videoUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            setMessage(`Error: ${result.message}`);
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          setMessage(`Upload failed: ${error.message}`);
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const toggleBold = () => {
    setStyles((prev) => ({ ...prev, bold: !prev.bold }));
  };

  const toggleUnderline = () => {
    setStyles((prev) => ({ ...prev, underline: !prev.underline }));
  };

  const toggleItalic = () => {
    setStyles((prev) => ({ ...prev, italic: !prev.italic }));
  };
  const applyTextStyles = (text) => {
    let styledText = text;
    if (styles.bold) {
      styledText = <b>{styledText}</b>;
    }
    if (styles.italic) {
      styledText = <i>{styledText}</i>;
    }
    if (styles.underline) {
      styledText = <u>{styledText}</u>;
    }
    return styledText;
  };
  const handleIconClick = (iconType) => {
    setSelectedIcon(iconType);
    setTextContent("");
    if (iconType === "multipleImages") {
      setSelectedMedia(iconType);
    }
    if (iconType === "code") {
      setCodeContent("");
    }
    if (iconType === "link") {
      setLinkUrl("");
    }
  };

  const handleMultipleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setImages(imageUrls);
  };

  const handleTextInput = (e) => {
    setTextContent(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && textContent.trim() !== "") {
      e.preventDefault();
      const formattedText =
        selectedIcon === "quote"
          ? `"${textContent.trim()}"`
          : textContent.trim();

      setContent((prevContent) => [
        ...prevContent,
        { type: selectedIcon, text: formattedText },
      ]);

      setTextContent("");
    }
    if (
      e.key === "Enter" &&
      codeContent.trim() !== "" &&
      selectedIcon === "code"
    ) {
      e.preventDefault();
      setContent((prevContent) => [
        ...prevContent,
        { type: "code", text: codeContent, language: codeLanguage },
      ]);
      setCodeContent("");
    }
    if (e.key === "Enter" && linkUrl.trim() !== "" && selectedIcon === "link") {
      e.preventDefault();
      setContent((prevContent) => [
        ...prevContent,
        { type: "link", text: linkUrl },
      ]);
      setLinkUrl("");
    }
  };

  const getStyle = (type) => {
    switch (type) {
      case "h1":
        return { fontSize: "2em" };
      case "h2":
        return { fontSize: "1.5em" };
      case "h3":
        return { fontSize: "1.17em" };
      case "h4":
        return { fontSize: "1em" };
      case "h5":
        return { fontSize: "0.83em" };
      case "h6":
        return { fontSize: "0.67em" };
      case "paragraph":
        return { fontSize: "1em" };
      case "orderedList":
      case "unorderedList":
        return { fontSize: "1em" };
      case "quote":
        return { fontSize: "1em", fontStyle: "italic" };
      case "checklist":
        return { fontSize: "1em" };
      case "code":
        return { fontSize: "1em" };
      case "link":
        return { fontSize: "1em", color: "blue", textDecoration: "underline" };

      default:
        return { fontSize: "1em" };
    }
  };

  const renderContent = () => {
    return content.map((item, index) => {
      let contentElement;

      switch (item.type) {
        case "h1":
          contentElement = (
            <h1 style={getStyle("h1")} key={index}>
              {applyTextStyles(item.text)}
            </h1>
          );
          break;
        case "h2":
          contentElement = (
            <h2 style={getStyle("h2")} key={index}>
              {applyTextStyles(item.text)}
            </h2>
          );
          break;
        case "h3":
          contentElement = (
            <h3 style={getStyle("h3")} key={index}>
              {applyTextStyles(item.text)}
            </h3>
          );
          break;
        case "h4":
          contentElement = (
            <h4 style={getStyle("h4")} key={index}>
              {applyTextStyles(item.text)}
            </h4>
          );
          break;
        case "h5":
          contentElement = (
            <h5 style={getStyle("h5")} key={index}>
              {applyTextStyles(item.text)}
            </h5>
          );
          break;
        case "h6":
          contentElement = (
            <h6 style={getStyle("h6")} key={index}>
              {applyTextStyles(item.text)}
            </h6>
          );
          break;
        case "paragraph":
          contentElement = (
            <p style={getStyle("paragraph")} key={index}>
              {applyTextStyles(item.text)}
            </p>
          );
          break;
        case "numberedList":
          contentElement = (
            <ol style={getStyle("numberedList")} key={index}>
              <li>{applyTextStyles(item.text)}</li>
            </ol>
          );
          break;
        case "unorderedList":
          contentElement = (
            <ul style={getStyle("unorderedList")} key={index}>
              <li>{applyTextStyles(item.text)}</li>
            </ul>
          );
          break;
        case "quote":
          contentElement = (
            <blockquote style={getStyle("quote")} key={index}>
              {applyTextStyles(item.text)}
            </blockquote>
          );
          break;
        case "checklist":
          contentElement = (
            <div key={index} style={getStyle("checklist")}>
              <input type="checkbox" /> {applyTextStyles(item.text)}
            </div>
          );
          break;
        case "code":
          contentElement = (
            <SyntaxHighlighter
              key={index}
              customStyle={getStyle("code")}
              language={item.language}
              style={vs}
            >
              {applyTextStyles(item.text)}
            </SyntaxHighlighter>
          );
          break;
        case "link":
          contentElement = (
            <a
              key={index}
              href={item.text}
              style={getStyle("link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {applyTextStyles(item.text)}
            </a>
          );
          break;
        case "uploadedImage":
          contentElement = (
            <img
              key={index}
              src={item.src}
              alt="Uploaded Image"
              style={{ width: "30vw" }}
            />
          );
          break;
        case "uploadedVideo":
          contentElement = (
            <video
              key={index}
              controls
              src={item.src}
              style={{ width: "40vw" }}
            />
          );
          break;
        default:
          contentElement = null;
      }

      return contentElement;
    });
  };

  const iconStyle = (iconType) => ({
    fontSize: "1.3rem",
    color: selectedIcon === iconType ? "#007bff" : "black",
    cursor: "pointer",
    backgroundColor: selectedIcon === iconType ? "#f0f0f0" : "transparent",
  });
  const getPlaceholder = () => {
    switch (selectedIcon) {
      case "h1":
        return "Enter Title";
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6":
        return "Enter Heading";
      case "paragraph":
        return "Write your paragraph";
      case "unorderedList":
      case "numberedList":
        return "Enter list item";
      case "checklist":
        return "Enter checklist item";
      case "quote":
        return "Enter quote";
      default:
        return "Enter text";
    }
  };
  const getPlaceholderStyle = () => {
    let fontSize = "1em";

    switch (selectedIcon) {
      case "h1":
        fontSize = "2em";
        break;
      case "h2":
        fontSize = "1.5em";
        break;
      case "h3":
        fontSize = "1.17em";
        break;
      case "h4":
        fontSize = "1em";
        break;
      case "h5":
        fontSize = "0.83em";
        break;
      case "h6":
        fontSize = "0.67em";
        break;
      default:
        fontSize = "1em";
    }

    return { fontSize };
  };
  const generateHTML = () => {
    let htmlContent = "";
    content.forEach((item) => {
      switch (item.type) {
        case "h1":
          htmlContent += `<h1>${applyTextStyles(item.text)}</h1>`;
          break;
        case "h2":
          htmlContent += `<h2>${applyTextStyles(item.text)}</h2>`;
          break;
        case "h3":
          htmlContent += `<h3>${applyTextStyles(item.text)}</h3>`;
          break;
        case "h4":
          htmlContent += `<h4>${applyTextStyles(item.text)}</h4>`;
          break;
        case "h5":
          htmlContent += `<h5>${applyTextStyles(item.text)}</h5>`;
          break;
        case "h6":
          htmlContent += `<h6>${applyTextStyles(item.text)}</h6>`;
          break;
        case "paragraph":
          htmlContent += `<p>${applyTextStyles(item.text)}</p>`;
          break;
        case "numberedList":
          htmlContent += `<ol><li>${applyTextStyles(item.text)}</li></ol>`;
          break;
        case "unorderedList":
          htmlContent += `<ul><li>${applyTextStyles(item.text)}</li></ul>`;
          break;
        case "quote":
          htmlContent += `<blockquote>${applyTextStyles(
            item.text
          )}</blockquote>`;
          break;
        case "checklist":
          htmlContent += `<div><input type="checkbox" /> ${applyTextStyles(
            item.text
          )}</div>`;
          break;
        case "code":
          htmlContent += `<pre><code class="language-${
            item.language
          }">${applyTextStyles(item.text)}</code></pre>`;
          break;
        case "link":
          htmlContent += `<a href="${
            item.text
          }" target="_blank" rel="noopener noreferrer">${applyTextStyles(
            item.text
          )}</a>`;
          break;
        default:
          break;
      }
    });

    // Add media content if needed
    if (selectedMedia === "image" && images.length > 0) {
      htmlContent += `<img src="${images[0]}" alt="Uploaded" style="max-width: 100%;" />`;
    }
    if (selectedMedia === "multipleImages" && images.length > 0) {
      images.forEach((img, index) => {
        htmlContent += `<img src="${img}" alt="Uploaded ${index}" style="max-width: 100%;" />`;
      });
    }
    if (selectedMedia === "video" && videoUrl) {
      htmlContent += `<video controls src="${videoUrl}" style="max-width: 100%;" />`;
    }

    return htmlContent;
  };
  const downloadHTML = () => {
    const htmlContent = generateHTML();
    console.log(htmlContent);
    const blob = new Blob([htmlContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "content.html";
    a.click();
    URL.revokeObjectURL(url);
  };

  const labelStyle = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };

  const inputStyle = {
    display: "none",
  };
  const [formState, setFormState] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
  });
  const [errors, setErrors] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
  });
  const validate = (status) => {
    let validationErrors = {};
    let isValid = true;
  
    if (!formState.title.trim()) {
      validationErrors.title = "*Please enter a title";
      isValid = false;
    }
  
    if (!formState.excerpt.trim()) {
      validationErrors.excerpt = "*Please enter an excerpt";
      isValid = false;
    }
  
    if (!formState.imageUrl) {
      validationErrors.imageUrl = "*Please upload a feature image";
      isValid = false;
    }
    setErrors(validationErrors); 
  
    if (isValid) {
      handleSubmit(status); 
    }
  };
  
  const handleSubmit = async (status) => {
    setTransactionLoaderState(true);
    const requestBody = {
      requests: content.map((item, index) => ({
        p_temp_post_id: tempPostId,
        p_block_type: item.type,
        p_block_content: item.text,
        p_block_style:"",
        p_block_image: item.src || "",
        p_block_order: item.order || index + 1,
        p_user_id: user.userid,
      })),
    };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/insertTempBlog`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const result = await response.json();

      if (result.status === "0" && result.result[0].result === "Y") {
        Publish(status);  
      } else {
        swal({
          title: "Error!",
          text: result.msg || "Failed to submit blog data.",
          icon: "error",
          button: "OK",
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlefeatureImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            // Set the uploaded image URL in the formState
            setFormState((prevState) => ({
              ...prevState,
              imageUrl,
            }));

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const Publish = async (status) => {
    try {
      const { title, excerpt, imageUrl } = formState;

      const raw = JSON.stringify({
        p_temp_post_id: tempPostId,
        p_post_title: title,
        p_post_excerpt: excerpt,
        p_post_author: "1",
        p_post_featured_image: imageUrl,
        p_status: status,
        p_product_name: "BillPunch",
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/publishBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const blogResult = data.result[0].result;
        const blogMessage = data.result[0].message;

        swal({
          title: "Success!",
          text: `${data.msg} - ${blogMessage}`,
          icon: "success",
          button: "OK",
        });
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: `Publish failed: ${error.message}`,
        icon: "error",
        button: "OK",
      });
    }
  };
  return (
    <div className="m-3">
      <div className="">
        <Form className="row">
          <Form.Group className="col-md-3" controlId="formTitle">
            <Form.Control
              type="text"
              placeholder="Enter title"
              name="title"
              value={formState.title}
              onChange={handleChange}
            />{" "}
            {errors.title && <p className="text-danger">{errors.title}</p>}
          </Form.Group>

          <Form.Group className="col-md-3" controlId="formExcerpt">
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Add excerpt"
              name="excerpt"
              value={formState.excerpt}
              onChange={handleChange}
            />{" "}
            {errors.excerpt && <p className="text-danger">{errors.excerpt}</p>}
          </Form.Group>

          <Form.Group
            className=" col d-flex align-items-center"
            controlId="formImage"
          >
            <Form.Label className="mb-0 mr-2">Select feature image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handlefeatureImageUpload}
              className="flex-grow-1"
            />{" "}
            {errors.imageUrl && (
              <p className="text-danger">{errors.imageUrl}</p>
            )}
          </Form.Group>
        </Form>
      </div>
      <div className="row d-flex justify-content-center p-0  m-2">
        <div className="col-md-2 col-6 p-0">
          <div className="card  pt-2 ">
            <h6 className="text-center">
              <strong>Text</strong>{" "}
            </h6>
            <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H1 heading</Tooltip>}
              >
                <LuHeading1
                  style={iconStyle("h1")}
                  onClick={() => handleIconClick("h1")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H2 heading</Tooltip>}
              >
                <LuHeading2
                  style={iconStyle("h2")}
                  onClick={() => handleIconClick("h2")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H3 heading</Tooltip>}
              >
                <LuHeading3
                  style={iconStyle("h3")}
                  onClick={() => handleIconClick("h3")}
                />
              </OverlayTrigger>
            </div>
            <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H4 heading</Tooltip>}
              >
                <LuHeading4
                  style={iconStyle("h4")}
                  onClick={() => handleIconClick("h4")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H5 heading</Tooltip>}
              >
                <LuHeading5
                  style={iconStyle("h5")}
                  onClick={() => handleIconClick("h5")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H6 heading</Tooltip>}
              >
                <LuHeading6
                  style={iconStyle("h6")}
                  onClick={() => handleIconClick("h6")}
                />
              </OverlayTrigger>
            </div>
            <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Paragraph</Tooltip>}
              >
                <FaParagraph
                  style={iconStyle("paragraph")}
                  onClick={() => handleIconClick("paragraph")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Unordered List</Tooltip>}
              >
                <FaList
                  style={iconStyle("unorderedList")}
                  onClick={() => handleIconClick("unorderedList")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Numbered List</Tooltip>}
              >
                <FaListOl
                  style={iconStyle("numberedList")}
                  onClick={() => handleIconClick("numberedList")}
                />
              </OverlayTrigger>
            </div>
            <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> checklist</Tooltip>}
              >
                <FaListCheck
                  style={iconStyle("checklist")}
                  onClick={() => handleIconClick("checklist")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Quote</Tooltip>}
              >
                <RiDoubleQuotesL
                  style={iconStyle("quote")}
                  onClick={() => handleIconClick("quote")}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Code Block</Tooltip>}
              >
                <IoMdCode
                  style={iconStyle("code")}
                  onClick={() => handleIconClick("code")}
                />
              </OverlayTrigger>
            </div>
            <h6 className="pt-2 text-center">
              <strong>Media</strong>{" "}
            </h6>
            <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Image</Tooltip>}
              >
                <label htmlFor="image-upload" style={labelStyle}>
                  <FaImage size="1.3rem" />
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    style={inputStyle}
                  />
                </label>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Link</Tooltip>}
              >
                <IoMdLink
                  style={iconStyle("link")}
                  onClick={() => handleIconClick("link")}
                />
              </OverlayTrigger>
              {/* <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Multiple Images</Tooltip>}
              >
                <IoIosImages
                  style={iconStyle("multipleImages")}
                  onClick={() => handleIconClick("multipleImages")}
                />
              </OverlayTrigger> */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Video</Tooltip>}
              >
                <label htmlFor="video-upload" style={labelStyle}>
                  <MdOutlineOndemandVideo size="1.3rem" />
                  <input
                    type="file"
                    id="video-upload"
                    accept="video/*"
                    multiple
                    onChange={handleVideoUpload}
                    style={inputStyle}
                  />
                </label>
              </OverlayTrigger>
            </div>
            {/* <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Link</Tooltip>}
              >
                <IoMdLink
                  style={iconStyle("link")}
                  onClick={() => handleIconClick("link")}
                />
              </OverlayTrigger>
            </div> */}
          </div>
          <div className="d-flex justify-content-start pt-2">
        <Button size="sm" onClick={() => validate("draft")}>
          Save as Draft
        </Button>
        <Button
          style={{ background: "#fff", border: "none" }}
          size="sm"
          type="submit"
        ></Button>
        <Button
          variant="info"
          size="sm"
          type="submit"
          style={{ height: "2rem" }}   onClick={() => validate("published")}
        >
          Publish
        </Button>
        {/* <Button size="sm" onClick={downloadHTML} className="btn btn-primary">
        Save as HTML
      </Button> */}
      </div>
        </div>
        <div className="col-md-8 col-12 px-md-2">
          <div className="card p-3">
            <textarea
              className="form-control"
              rows="3"
              value={textContent}
              placeholder={getPlaceholder()}
              style={getPlaceholderStyle()}
              onChange={handleTextInput}
              onKeyPress={handleKeyPress}
            />

            <div className="mt-2">
              {selectedMedia === "multipleImages" && (
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleMultipleImageUpload}
                />
              )}
            </div>
            <div className="mt-4">{renderContent()}</div>
          </div>
        </div>
        <div className="col-md-2 col-4 p-0">
          <div className="card  pt-2 ">
            <h6 className="text-center">
              <strong>Style</strong>{" "}
            </h6>
            <div className="row" style={{ justifyContent: "space-around" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Bold</Tooltip>}
              >
                <FaBold onClick={toggleBold} />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Underline</Tooltip>}
              >
                <FaUnderline onClick={toggleUnderline} />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Italic</Tooltip>}
              >
                <FaItalic onClick={toggleItalic} />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  );
};
export default Customblog;
