import React, { useContext, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const Singlesignon = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [formData, setFormData] = useState({
    Firmname: "",
    Firmowner: "",
    Firmstrdate: "",
    Firmgst: "",
    Firmtype: "",
    Pan: "",
    Firmmember: "",
    Firmmail: "",
    Firmmobile: "",
    Aadhar: "",
    FirmAddress: "",
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      const { user_name, user_phone, timestamp } = location.state;
      setFormData((prevFormData) => ({
        ...prevFormData,
        Firmowner: user_name,
        Firmmobile: user_phone,
      }));
    }
  }, [location.state]);

  useEffect(() => {
    // Fetch recent new firm data
    fetchRecentNewFirm();
  }, []);
  const formatDate = (dateStr) => {
    const [month, day, year] = dateStr.split("/");
    return `${day}/${month}/${year}`;
  };
  const fetchRecentNewFirm = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        mobile: location.state.user_phone,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetRecentNewFirm`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch recent new firm data");
      }

      const data = await response.json();
      if (data.status === "0" && data.data.length > 0) {
        const {
          firm_name,
          firm_owner_name,
          firm_startdate,
          firm_gst,
          firm_type,
          firm_members,
          firm_email,
          firm_mobile,
          owner_pan,
          owner_aadhar,
          firm_address,
        } = data.data[0];

        const formattedStartDate = formatDate(firm_startdate);
        setFormData({
          Firmname: firm_name,
          Firmowner: firm_owner_name,
          Firmstrdate: formattedStartDate,
          Firmgst: firm_gst,
          Firmtype: firm_type,
          Firmmember: firm_members,
          Firmmail: firm_email,
          Firmmobile: firm_mobile,
          Pan: owner_pan,
          Aadhar: owner_aadhar,
          FirmAddress: firm_address,
        });
      } else {
       
      }
    } catch (error) {
      console.error("Error fetching recent new firm data:", error);
      swal("Error", "Failed to fetch recent new firm data", "error");
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = {};

    // Validation rules
    if (!formData.Firmname) {
      errors.Firmname = "Firm name is required";
      isValid = false;
    } else if (formData.Firmname.trim() !== formData.Firmname) {
      errors.Firmname = "Firm name should not start with spaces";
      isValid = false;
    }

    if (!formData.Firmowner) {
      errors.Firmowner = "Firm owner is required";
      isValid = false;
    } else if (formData.Firmowner.trim() !== formData.Firmowner) {
      errors.Firmowner = "Firm owner should not start with spaces";
      isValid = false;
    }

    if (!formData.Firmstrdate) {
      errors.Firmstrdate = "Firm start date is required";
      isValid = false;
    }

    if (!formData.Firmmember) {
      errors.Firmmember = "Firm size required";
      isValid = false;
    }

    if (!formData.Firmtype) {
      errors.Firmtype = "Firm type is required";
      isValid = false;
    }

    if (!formData.Firmmail) {
      errors.Firmmail = "Firm email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.Firmmail)) {
      errors.Firmmail = "Invalid email address";
      isValid = false;
    }
    if (!formData.Firmmobile) {
      errors.Firmmobile = "Firm mobile is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.Firmmobile)) {
      errors.Firmmobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }
    if (!formData.FirmAddress) {
      errors.FirmAddress = "Firm address is required";
      isValid = false;
    }

    setErrors(errors);
    if (Object.keys(errors).length === 0 && isValid) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firm_name: formData.Firmname,
        firm_owner_name: formData.Firmowner,
        firm_startdate: formData.Firmstrdate,
        firm_gst: formData.Firmgst,
        firm_type: formData.Firmtype,
        firm_members: formData.Firmmember,
        firm_email: formData.Firmmail,
        firm_mobile: formData.Firmmobile,
        owner_pan: formData.Pan,
        owner_aadhar: formData.Aadhar,
        firm_address: formData.FirmAddress,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddFirmQueryNew`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal({
          title: "Successfully Created Your Account",
          text: data.result,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willConfirm) => {
          if (willConfirm) {
            Logout();
          }
        });
      } else {
        swal("Error", data.msg, "warning");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to submit form", "error");
    }
    setTransactionLoaderState(false);
  };
  const Logout = () => {
    const callbackUrl = `${window.location.origin}`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
    window.location.href = redirectUrl; 
  };
  const headerStyle = {
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
  };

  return (
    <>
      <div>
        <div style={headerStyle}>
          <h3 style={{ color: "blue" }}>Welcome To BillPunch</h3>
        </div>
        <div className="d-flex justify-content-center">
          <div className="mt-md-3 mt-4">
            <div className="d-flex justify-content-center">
              <img
                alt="Billpunch"
                src={require("../../../assets/images/logoblack.png")}
                style={{ height: "2rem" }}
              />
            </div>

            <Form className="mt-4" onSubmit={validateForm}>
              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmname">
                  <Form.Label>Firm Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm name"
                    name="Firmname"
                    value={formData.Firmname}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmname}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col" controlId="Firmowner">
                  <Form.Label>Firm Owner</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm owner"
                    name="Firmowner"
                    value={formData.Firmowner}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmowner}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmowner}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmstrdate">
                  <Form.Label>Firm Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="Firmstrdate"
                    value={formData.Firmstrdate}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmstrdate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmstrdate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col" controlId="Firmgst">
                  <Form.Label>Firm GST (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm GST"
                    name="Firmgst"
                    value={formData.Firmgst}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmgst}
                  />
               
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmtype">
                  <Form.Label>Firm Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm type"
                    name="Firmtype"
                    value={formData.Firmtype}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmtype}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmtype}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col" controlId="Firmmember">
                  <Form.Label>Firm Members</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm members"
                    name="Firmmember"
                    value={formData.Firmmember}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmmember}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmmember}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmmail">
                  <Form.Label>Firm Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter firm email"
                    name="Firmmail"
                    value={formData.Firmmail}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmmail}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col" controlId="Firmmobile">
                  <Form.Label>Firm Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm mobile"
                    name="Firmmobile"
                    value={formData.Firmmobile}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmmobile}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmmobile}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Pan">
                  <Form.Label>Firm Pan (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm Pan"
                    name="Pan"
                    value={formData.Pan}
                    onChange={handleChange}
                    isInvalid={!!errors.Pan}
                  />
               
                </Form.Group>
                <Form.Group className="col" controlId="Aadhar">
                  <Form.Label>Firm Aadhaar (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm Aadhaar"
                    name="Aadhar"
                    value={formData.Aadhar}
                    onChange={handleChange}
                    isInvalid={!!errors.Aadhar}
                  />
                 
                </Form.Group>
              </div>
              <div className="row m-0 p-0">
              <Form.Group className="col" controlId="FirmAddress">
                <Form.Label>Firm Address</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter firm address"
                  name="FirmAddress"
                  value={formData.FirmAddress}
                  onChange={handleChange}
                  isInvalid={!!errors.FirmAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.FirmAddress}
                </Form.Control.Feedback>
              </Form.Group>
</div>
              <div className="px-3">
                <Button className="btn-sm" variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Singlesignon;
