import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { useQuery } from "../../custom-hooks/useQuery";
import { Button } from "react-bootstrap";

const Approvalhr = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              Leave Approval
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
              style={activeTab === "tab2" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab2")}
            >
              WFH Approval
            </button>
          </li>
        </ul>
        <div className="tab-content p-3">
          {activeTab === "tab1" && <LeaveApprovalAdmin />}
          {activeTab === "tab2" && <Wfhapproval />}
        </div>
      </div>
    </>
  );
};

const LeaveApprovalAdmin = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Employeeid, setEmployeeid] = useState(-1);

  const { password, username } = billpunchbasicauth;
  const GetAllEmployeeLeave = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllEmployeeLeave`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
        if (data1.data.length > 0) {
          setEmployeeid(data.data[0].id);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const ApproveRejectLeave = async (leaveId, status) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        leaveid: leaveId,
        status: status,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ApproveRejectLeave`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        GetAllEmployeeLeave();
      } else {
        swal("Success", data.result, "success");
        GetAllEmployeeLeave();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };
  const handleApprove = async (id) => {
    try {
      const leaveId = data1.find(
        (item) => item.pk_leave_id === id
      )?.pk_leave_id;

      if (leaveId) {
        await ApproveRejectLeave(leaveId, "A");
      }
    } catch (error) {
      console.error("Error approving leave:", error);
    }
  };

  const handleReject = async (id) => {
    try {
      const leaveId = data1.find(
        (item) => item.pk_leave_id === id
      )?.pk_leave_id;

      if (leaveId) {
        await ApproveRejectLeave(leaveId, "R");
      }
    } catch (error) {
      console.error("Error rejecting leave:", error);
    }
  };

  useEffect(() => {
    GetAllEmployeeLeave();
  }, [Employeeid]);
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  return (
    <>
      <div
      // className="d-flex justify-content-center align-items-center"
      // style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table "
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Name</th>
              <th style={{ color: "#0074D9" }}>Category</th>
              <th style={{ color: "#0074D9" }}>Reason</th>
              <th style={{ color: "#0074D9" }}>From</th>
              <th style={{ color: "#0074D9" }}> ToDate </th>
              <th style={{ color: "#0074D9" }}>Status</th>
              <th style={{ color: "#0074D9" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data1.length === 0 ? (
              <tr>
                <td
                  className="px-2"
                  colSpan="7"
                  style={{ textAlign: "center" }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              data1.map((item) => (
                <tr key={item.empid}>
                  <td>{item.employee_name}</td>
                  <td>{item.leave_category}</td>
                  <td>{item.leave_description}</td>
                  <td>{new Date(item.form_date).toLocaleDateString()}</td>
                  <td>{new Date(item.to_date).toLocaleDateString()}</td>
                  <td>
                    {item.status === "R" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Rejected
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "A" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Approved
                      </span>
                    )}
                  </td>

                  <td>
                    {accessData &&
                      accessData.map(
                        (module) =>
                          module.data &&
                          module.data.map((role) =>
                            role.role_name === "Approve/Reject Leave" ? (
                              <span key={role.pk_role_id}>
                                {role.access === "Y" ? (
                                  <>
                                    {item.status === "P" ? (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleApprove(item.pk_leave_id)
                                          }
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleReject(item.pk_leave_id)
                                          }
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.status === "P" ? (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleApprove(item.pk_leave_id)
                                          }
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleReject(item.pk_leave_id)
                                          }
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </span>
                            ) : null
                          )
                      )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
const Wfhapproval = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const { password, username } = billpunchbasicauth;
  const GetAllEmployeeLeave = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetWorkFromHome`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const ApproveRejectLeave = async (leaveId, status, firmid, empid) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: firmid,
        empid: empid,
        pk_wfh_id: leaveId,
        status: status,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ApproveWfhStatus`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        GetAllEmployeeLeave();
      } else {
        swal("Success", data.result, "success");
        GetAllEmployeeLeave();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };
  const handleApprove = async (id) => {
    try {
      const leave = data1.find((item) => item.pk_wfh_id === id);

      if (leave) {
        await ApproveRejectLeave(leave.pk_wfh_id, "A", leave.firmid, leave.empid);
      }
    } catch (error) {
      console.error("Error approving leave:", error);
    }
  };

  const handleReject = async (id) => {
    try {
      const leave = data1.find((item) => item.pk_wfh_id === id);

      if (leave) {
        await ApproveRejectLeave(leave.pk_wfh_id, "R", leave.firmid, leave.empid);
      }
    } catch (error) {
      console.error("Error rejecting leave:", error);
    }
  };


  useEffect(() => {
    GetAllEmployeeLeave();
  }, []);
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  return (
    <>
      <div
      >
        <table
          className="PMS-collection-table "
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "50%" }} />
          </colgroup>
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Name</th>
              <th style={{ color: "#0074D9" }}>Reason</th>
              <th style={{ color: "#0074D9" }}>From-To </th>
              <th style={{ color: "#0074D9" }}>Status</th>
              <th style={{ color: "#0074D9" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data1.length === 0 ? (
              <tr>
                <td
                  className="px-2"
                  colSpan="7"
                  style={{ textAlign: "center" }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              data1.map((item) => (
                <tr key={item.pk_wfh_id}>
                  <td>{item.fullname}<br/>{item.designation}</td>
                  <td>{item.reason}</td>
                  <td>
                    {new Date(item.from_date).toLocaleDateString()}
                    <br />
                    {new Date(item.to_date).toLocaleDateString()}
                  </td>

                  <td>
                    {item.status === "R" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Rejected
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "A" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Approved
                      </span>
                    )}
                  </td>

                  <td>
                    {accessData &&
                      accessData.map(
                        (module) =>
                          module.data &&
                          module.data.map((role) =>
                            role.role_name === "Approve/Reject Leave" ? (
                              <span key={role.pk_wfh_id}>
                                {role.access === "Y" ? (
                                  <>
                                    {item.status === "P" ? (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleApprove(item.pk_wfh_id)
                                          }
                                        >
                                          Approve
                                        </button>
                                        <Button
                                          style={{
                                            background: "#fff",
                                            border: "none",
                                          }}
                                          disabled
                                        ></Button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleReject(item.pk_wfh_id)
                                          }
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.status === "P" ? (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleApprove(item.pk_wfh_id)
                                          }
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleReject(item.pk_wfh_id)
                                          }
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </span>
                            ) : null
                          )
                      )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Approvalhr;
