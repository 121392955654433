import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_UAT_API,
  BASE_URL_APISERVICES,
} from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BulkSalary } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";

export const columns = [
  {
    dataField: "ind + 1",
    text: "Sr.",
  },
 
  {
    dataField: "employeename",
    text: "Name",
  },
  {
    dataField: "category",
    text: "Category",
  },
  {
    dataField: "salary_amount",
    text: "Amount",
  },
  {
    dataField: "pf",
    text: "EPF",
  },
  {
    dataField: "month",
    text: "Month",
  },
  {
    dataField: "indate",
    text: "Date",
  },
  
  {
    dataField: "status",
    text: "Status",
  },

];

const BulkSalaryProcess = () => {
  const history = useHistory();
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const { password , username } = billpunchbasicauth;
 
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const fetchSalaryRecords = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
    
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/FetchSalaryRecord`,
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error(error);
    }
  };

  const proccessSalary = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        id: id,
        status: "Y",
        type: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/proccessEmployeeSalary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Salary Processed Successfully", "", "success");
        fetchSalaryRecords();
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: ".4rem",
    padding: "0px",
    backgroundColor: "#fff",
    width: "4rem", 
    height: "2.5rem",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    height:"2rem",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };



  const getPageAccess = (menuId) => {
    const sub_menus_access_info = JSON.parse(
      sessionStorage.getItem("sub_menus")
    );

    if (sub_menus_access_info && Array.isArray(sub_menus_access_info)) {
      sub_menus_access_info.forEach((acc) => {
        if (acc.pk_menu_id == menuId) {
          setPageAccess(acc);
        }
      });
    } else {
      // Handle the case when sub_menus_access_info is null or not an array
      console.error("sub_menus_access_info is null or not an array");
    }
  };

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    employeeId: "",
    month: "",
    category: "",
    amount: "",
  });
  const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const categories = ["Salary", "Bonus", "Expenses"];
  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal(true);
    setFormData({
      employeeId: rowData.employee_name,
      month: rowData.month,
      amount: parseFloat(rowData.amount).toFixed(2),
      category: rowData.category,
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const [selectedIds, setSelectedIds] = useState([]);
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const handleCheckboxChange = (pk_record_id) => {
    setSelectedIds((prevSelectedIds) => {
      const updatedSelectedIds = [...prevSelectedIds];

      if (updatedSelectedIds.includes(pk_record_id)) {
        const index = updatedSelectedIds.indexOf(pk_record_id);
        updatedSelectedIds.splice(index, 1);
      } else {
        updatedSelectedIds.push(pk_record_id);
      }

      return updatedSelectedIds;
    });
  };

  const handleSelectAllChange = () => {
    setSelectedIds((prevSelectedIds) => {
      return prevSelectedIds.length === data.length
        ? []
        : data.map((e) => e.pk_record_id);
    });
  };

  const handleProcessAllClick = () => {
    if (selectedIds.length === 0) {
      swal("Please select at least one item to process.", "", "warning");
    } else {
      setShowProcessAllModal(true);
    }
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
    
      var raw = JSON.stringify({  firmid:  user.firm_id,});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const [showBulkModal, setShowBulkModal] = useState(false);

  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const openBulkModal = () => setShowBulkModal(true);
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);

  const [department, setDepartment] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const updateFilteredEmployeeList = (updatedList) => {
    setFilteredEmployeeList(updatedList);
  };
 
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const handleProcessAll = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
    
      const requestOptions = {
        method: "POST",
        headers:myHeaders,
        body: JSON.stringify({
          userid: "string", 
          amount: "string", 
          tpin: "string",
          serviceType: "string",
          accNo: "string", 
          ifsc: "string", 
          bankName: "string", 
        }),
        redirect: "follow",
      };

      const response = await fetch(
        "https://apiuat.remittpe.com/api/Salary/SalaryManagement/SalaryPayroll",
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        swal("Process successful", "", "success");
      } else {
        swal("Process", data.message || "Error", "error");
      }
    } catch (error) {
     swal("API call failed", "An error occurred", "error");
    }
  };


  useEffect(() => {
    fetchSalaryRecords();
    getPageAccess(14);
    getDepartmentList();
  }, []);

  return (
    <div className="m-2" style={{ background: "#fff" }}>
      
      <BulkSalary
          showBulkModal={showBulkModal}
          setShowBulkModal={setShowBulkModal}
          
        filteredEmployeeList={filteredEmployeeList}
        updateFilteredEmployeeList={updateFilteredEmployeeList}
        />

      <div className="row ">
        <div className="col">
        <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `salary_ledger${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../../assets/images/exportexcel.png")}
              style={{ width: "1.6rem", marginLeft: "15px", color:"rgba(43, 193, 85, 1)" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>
        <div className="col-4 d-flex justify-content-end">
          {" "}
          <Link className="m-2" to="/retailer/ProcessSalary">
            <Button
              className="d-flex align-items-center"
              style={{ height: "2rem", fontSize: "1rem", borderRadius:".4rem" }}
              onClick={() => {
                history.push("/retailer/ProcessSalary");
                // toggleOffcanvas();
              }}
            >
              Back
            </Button>
          </Link>
        </div>
      </div>
      <div className="row  ">
       
       
            <div style={searchBarStyle} className="col-md-2 mx-3">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>
            <div className="col-md-1">
          <select
            style={{
              width: "5rem",
              height: "2.5rem",
              borderRadius: ".4rem",
              border: "1px #ced4da solid",
            }}
            value={selectedYear}
            onChange={handleYearChange}
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value={"2025"}>2025</option>
              <option value={"2026"}>2026</option>
          </select>
        </div>
        <div className="col-md-1">
          <select
            style={{
              width: "5rem",
              height: "2.5rem",
              borderRadius: ".4rem",
              border: "1px #ced4da solid",
            }}
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <select
            style={{
              width: "9rem",
              height: "2.5rem",
              borderRadius: ".4rem",
              border: "1px #ced4da solid",
            }}
            value={department}
            onChange={(e) => {
              setDepartment(e.target.value);
            }}
          >
            <option value="">All Department</option>
            {departmentData.map((dept) => (
              <option key={dept.id} value={dept.cat1}>
                {dept.cat1}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2 col">
       
       <Button   style={{
             backgroundColor: "rgba(43, 193, 85, 0.1)",
             color: "rgba(43, 193, 85, 1)",
             bordercolor: "rgba(43, 193, 85, 1)",
             borderRadius: ".4rem",
             border: "1px solid",
             height: "2.5rem",
           }}
        onClick={openBulkModal}
        >
        Generate Salary
       </Button></div>
            <div className=" col-md-2 col d-flex justify-content-end">
        <Button
          className="d-flex align-items-center"
          style={{ height: "2.5rem", fontSize: "1rem", borderRadius:".4rem" ,}}
          onClick={handleProcessAll}
        >
          Process All
        </Button>
      </div>
           
          </div>
 
      <hr className="m-0 p-0" />

      <div style={{ width: "100%" }}>
        <div style={{ alignItems: "center" }}>
          <div style={{ flex: "1" }}></div>
        </div>
      </div>
      <div className=" scroll-inner admin-fix-width borderless-table ">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr
            className="borderless-table text-center border-bottom"
            style={{ background: "white", color: "black" }}
          >
            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              <input
                type="checkbox"
                onChange={handleSelectAllChange}
                checked={selectedIds.length === data.length}
              />
            </th>
            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="text-center  "
              style={{ background: "white", color: "black" }}
            >
              Name
            </th>

            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              Category
            </th>

            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              Amount
            </th>
            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              EPF
            </th>
            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              Month
            </th>
            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              date
            </th>
            <th
              className=" text-center  "
              style={{ background: "white", color: "black" }}
            >
              Status
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(e.pk_record_id)}
                  checked={selectedIds.includes(e.pk_record_id)}
                />
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {ind + 1}
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                <div>{e.employeename || "NA"}</div>
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {" "}
                <div>{e.category}</div>
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {" "}
                {twoDecimalPlaces(e.salary_amount)}
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {" "}
                <div>{e.pf}</div>
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {" "}
                <div>{e.month}</div>
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {" "}
                <div>{e.indate}</div>
              </td>
              <td
                className="text-center align-middle"
                style={{ border: "none", fontSize: ".8rem" }}
              >
                {e.status.toString() == "Y" ? (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(43, 193, 85, 0.1)",
                      color: "rgba(43, 193, 85, 1)",
                      bordercolor: "rgba(43, 193, 85, 1)",
                    }}
                  >
                    {"Processed"}
                  </label>
                ) : (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(254, 128, 36, 0.1)",
                      color: "rgba(254, 128, 36, 1)",
                      bordercolor: "rgba(254, 128, 36, 1)",
                    }}
                  >
                    {"Pending"}
                  </label>
                )}
              </td>{" "}
              {/* <td
                                className="text-center align-middle"
                                style={{ border: "none", fontSize: ".8rem" }}
                            >
                {" "}
                {pageAccess.str_access_rights == 1 && (
                  <div className="row m-0 p-0 ">
                    {e.status == "N" && (
                      <Button
                        style={{
                          borderRadius: "5px",
                          border: "1px none",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          color: "rgba(255, 164, 27, 1)",
                          bordercolor: "rgba(255, 255, 255, 1)",
                        }}
                        className="btn btn-sm  rounded-100 m-0 p-0"
                        onClick={() => {
                          refundVerification(e.id, e.amount, e.employee_name);
                        }}
                      >
                        {" "}
                        <div className="hover-container">
                          <img
                            src={require("../../../assets/images/Raise Ticket.png")}
                            style={{ width: "" }}
                          />

                          <div
                            style={positionStyle1}
                            className="hover-text bg-dark"
                          >
                            {" "}
                            Proccess Salary
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                )}
              </td> */}
            </tr>
          ))}
        </table>
      </div>
      <Modal
        show={showProcessAllModal}
        onHide={() => setShowProcessAllModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="col" controlId="monthDropdown">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {" Select Month"}
              </Form.Label>
              <Form.Control
                as="select"
                name="month"
                value={formData.month}
                onChange={handleChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col" controlId="categoryDropdown">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Select category`}
              </Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default BulkSalaryProcess;
