import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { SalaryExpenseAdminModal, SalaryExpenseModal } from "./Modal";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";

const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

const generateCalendarMatrix = (year, month) => {
  const daysInMonth = getDaysInMonth(year, month);
  const firstDay = getFirstDayOfMonth(year, month);

  let calendarMatrix = [];
  let week = [];

  // Fill the first week with empty days until the first day of the month
  for (let i = 0; i < firstDay; i++) {
    week.push(null);
  }

  // Fill the days of the month
  for (let day = 1; day <= daysInMonth; day++) {
    week.push(day);
    if (week.length === 7) {
      calendarMatrix.push(week);
      week = [];
    }
  }

  // Fill the remaining days of the last week with empty days
  while (week.length < 7) {
    week.push(null);
  }

  calendarMatrix.push(week);
  return calendarMatrix;
};

// Calendar component
const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const { password, username } = billpunchbasicauth;

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const today = currentDate.getDate();

  const calendarMatrix = generateCalendarMatrix(year, month);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handlePrevMonth = () => {
    setCurrentDate(new Date(year, month - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(year, month + 1, 1));
  };

  useEffect(() => {
    const getEmployeeAttendance = async (year, month) => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            empid: "32", // Replace with dynamic employee ID if needed
            month: (month + 1).toString(), // Adjusting month to 1-based index
            year: year.toString(),
          }),
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeAttendanceDetails`,
          requestOptions
        );
        const data = await res.json();
        if (data.status === "0" && data.data) {
          setAttendanceData(data.data.filter(attendance => attendance.attendance_status === "Y"));
        } else {
          setAttendanceData([]); // Set empty array if no data available
        }
      } catch (error) {
        console.error("error", error);
      }
    };

    getEmployeeAttendance(year, month);
  }, [year, month, username, password]);

  useEffect(() => {
    const getHolidays = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
       
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({}),
          redirect: "follow",
        };
    
        const res = await fetch(`${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmHoliday`, requestOptions);
        const data = await res.json();
        if (data.status === "0" && data.data) {
          setHolidays(data.data);
        }
      } catch (error) {
        console.error("error", error);
      }
    };
    getHolidays();
  }, [username, password]);

  const isDatePresent = (date) => {
    return attendanceData.some(
      (attendance) => new Date(attendance.attendance_date).getDate() === date
    );
  };

  const getHolidayName = (date) => {
    const holiday = holidays.find(holiday => {
      const parts = holiday.datee.split('/');
      const holidayDate = new Date(parts[2], parts[1] - 1, parts[0]); // year, month (0-based), day
      return holidayDate.getDate() === date && holidayDate.getMonth() === month;
    });
    return holiday ? holiday.holiday : null;
  };
  
  const calendarHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const calendarBodyStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
  };

  const weekDayStyle = {
    padding: '10px',
    textAlign: 'center',
  };

  const sundayStyle = {
    backgroundColor: '#f8d7da', // Light red background for Sundays
  };

  const todayStyle = {
    backgroundColor: '#d4edda', // Light green background for the current date
    fontWeight: 'bold',
  };

  return (
    <div>
            <div style={calendarHeaderStyle}>
        <button onClick={handlePrevMonth}>{"<"}</button>
        <h2>{monthNames[month]} {year}</h2>
        <button onClick={handleNextMonth}>{">"}</button>
      </div>
      <div style={calendarBodyStyle}>
        <div className="week-days">
        {weekDays.map((day) => (
            <div key={day} style={weekDayStyle}>
              {day}
            </div>
          ))}
        </div>
        {calendarMatrix.map((week, weekIndex) => (
          <div key={weekIndex} className="calendar-week">
            {week.map((day, dayIndex) => (
              <div
                key={dayIndex}
                style={{
                  ...weekDayStyle,
                  ...(dayIndex === 0 ? sundayStyle : {}),
                  ...(day === today ? todayStyle : {}),
                }}
              >
                {day && isDatePresent(day) && getHolidayName(day) ? (
                  <span style={{color:"orange"}}>
                    {day}  Present, {getHolidayName(day)}
                  </span>
                ) : day && isDatePresent(day) ? (
                  <span style={{color:"green"}}>
                    {day}  Present
                  </span>
                ) : day ? (
                  <span style={{color:"blue"}}>
                    {day}  {getHolidayName(day)}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};


const ExpensesAdmin = () => {
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  const openExpenseModal = () => {
    setIsExpenseModalOpen(true);
  };

  const closeExpenseModal = () => {
    setIsExpenseModalOpen(false);
  };

  const { accessData } = useContext(GlobalContext);

  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  const doughnutData = {
    labels: ["Category 1", "Category 2", "Category 3"],
    datasets: [
      {
        data: [30, 40, 20],
        backgroundColor: ["#40E0D0", "#5D8AA8", "#004F98"],
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      arc: {
        borderWidth: 0.8,
      },
    },
    cutoutPercentage: 70,
  };

  const history = useHistory();

  const Viewexpanse = () => history.push("/retailer/ViewSalaryExpenseAdmin");

  return (
    <>
      {/* <div>
        <Calendar />
      </div> */}
      <div className="row mt-md-3 mt-sm-3 p-0 mt-sm-3">
        <div className="col-6 mx-md-5 mx-sm-3 mb-sm-3">
          <h1>
            <b> All your expenses in one place</b>
          </h1>
          <p style={{ fontSize: "1rem" }}>
            Save hours of work by tracking expenses
          </p>
          <div className="row m-0 p-0">
          <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>@</b>
            </h2>
            <p
              className="d-flex d-flex align-items-center col m-0 p-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp; Add & Manage your expenses here
            </p>
          </div>
          <div className="row p-0 m-0">
          <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>#</b>
            </h2>
            <p
              className="d-flex d-flex align-items-center col m-0 p-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp;  Organize your expenses into categories
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>@</b>
            </h2>
            <p
              className="d-flex align-items-center col p-0 m-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp;  See where you're spending your money
            </p>
          </div>
          <div className="mt-md-3 mt-sm-2">
            {accessData &&
              accessData.map((module) =>
                module.data &&
                module.data.map((role) =>
                  role.role_name === "Add Expense" ? (
                    role.access === "Y" ? (
                      <span key={role.pk_role_id}>
                        <button
                          type="button"
                          className="btn btn-sm d-flex align-items-center justify-content-center"
                          style={{
                            background: "#2ca01c",
                            height: "2.5em",
                            border: "None",
                            color: "#fff",
                          }}
                          onClick={openExpenseModal}
                        >
                          Add expense manually
                        </button>
                      </span>
                    ) : (
                      <span key={role.pk_role_id}>
                        <button
                          className="btn btn-sm btn-primary d-flex justify-content-center"
                          style={{
                            color: "#fff",
                            height: "2rem",
                            border: "none",
                            borderRadius: ".4rem",
                            display: "flex",
                            alignItems: "center",
                            fontSize: ".8rem",
                            backgroundColor: "gray",
                            cursor: "not-allowed",
                          }}
                          disabled
                        >
                          Add expense manually
                        </button>
                      </span>
                    )
                  ) : null
                )
              )}
              
          </div>
          <div className="mt-md-3 mt-sm-2">
            {accessData &&
              accessData.map((module) =>
                module.data &&
                module.data.map((role) =>
                  role.role_name === "View Expense" ? (
                    role.access === "Y" ? (
                      <span key={role.pk_role_id}>
                        <button
                          type="button"
                          className="btn btn-sm d-flex align-items-center justify-content-center"
                          style={{
                            background: "#2ca01c",
                            height: "2.5em",
                            border: "None",
                            color: "#fff",
                          }}
                          onClick={Viewexpanse}
                        >
                          View expense
                        </button>
                      </span>
                    ) : (
                      <span key={role.pk_role_id}>
                        <button
                          className="btn btn-sm btn-primary d-flex justify-content-center"
                          style={{
                            color: "#fff",
                            height: "2rem",
                            border: "none",
                            borderRadius: ".4rem",
                            display: "flex",
                            alignItems: "center",
                            fontSize: ".8rem",
                            backgroundColor: "gray",
                            cursor: "not-allowed",
                          }}
                          disabled
                        >
                          View expense
                        </button>
                      </span>
                    )
                  ) : null
                )
              )}
          </div>
        </div>

        <div className="col mr-md-4 mr-sm-3 d-flex justify-content-center">
          <div
            style={{
              background: "#fff",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h6 style={{ color: "grey" }} className="m-3">
              SPENDING LAST MONTH
            </h6>
            <h5 style={{ color: "grey" }} className="m-3">
              ₹1222
            </h5>
            <Doughnut data={doughnutData} options={doughnutOptions} />
            <SalaryExpenseAdminModal
              isOpen={isExpenseModalOpen}
              closeModal={closeExpenseModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesAdmin;
